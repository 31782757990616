import {Pipe, PipeTransform} from '@angular/core';
import {SlugService} from '@app/services/slug.service';

@Pipe({
  name: 'slug'
})
export class SlugPipe implements PipeTransform {
  constructor(private slugService: SlugService) {
  }

  transform(input: string): string {
    return this.slugService.toSlug(input);
  }
}
