import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterchangeService {

  private pagetitle: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  public SetPageTitle(title: string) {
    this.pagetitle.emit(title);
  }

  public GetEmitter(): EventEmitter<string> {
    return this.pagetitle;
  }

}
