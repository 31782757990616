<nav>
  <ul class="pagination justify-content-center my-2">
    <li class="page-item" (click)="selectPage(1, $event)" [class.disabled]="currentPage == 1">
      <a class="page-link" title="Go to first page">&laquo;</a>
    </li>
    <li class="page-item" (click)="selectPage(currentPage - 1, $event)" [class.disabled]="currentPage == 1">
      <a class="page-link" title="Go to previous page">&lsaquo;</a>
    </li>
    <li class="page-item disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
      <a class="page-link">...</a>
    </li>
    <li *ngFor="let page of pages | async" [class.active]="page == currentPage">
      <a class="page-link" (click)="selectPage(page, $event)">
        {{page}}
      </a>
    </li>
    <li class="page-item disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
      <a class="page-link">...</a>
    </li>
    <li class="page-item" (click)="selectPage(currentPage + 1, $event)" [class.disabled]="currentPage == totalPages">
      <a class="page-link" title="Go to next page">&rsaquo;</a>
    </li>
    <li class="page-item" (click)="selectPage(totalPages, $event)" [class.disabled]="currentPage == totalPages">
      <a class="page-link" title="Go to last page">&raquo;</a>
    </li>
  </ul>
</nav>
