<nav class="navbar navbar-light bg-light d-flex flex-row justify-content-between align-items-center">
  <h1>Upcoming Orders</h1>
</nav>

<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <p *ngIf="loading" class="text-center">Loading...</p>
      <p *ngIf="!loading && (!orders || orders.length === 0) " class="text-center">No upcoming order found</p>
      <p *ngIf="error" class="text-center text-danger mt-3">{{error}}</p>

      <table class="table table-responsive-md table-bordered table-striped" *ngIf="!loading && orders">
        <thead>
        <tr>
          <th>Order #</th>
          <th>Date</th>
          <th>Time</th>
          <th>Location</th>
          <th>Items</th>
          <th>Total</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orders">
          <td>
            <a [routerLink]="['/order', order._id ? order._id : '']">
              <p>{{order?.orderNumber.slice(0, 5)}}</p>
            </a>
            <small>CREATED {{order.createdAt | date: 'dd/MM/yy HH:mm a'}}</small>
            <div class="sb-tag mb-2 mt-2" matTooltip="Delivery status" style="text-transform: capitalize"
                 *ngIf="order.lastDeliveryStatus">{{order.lastDeliveryStatus || '-'}}</div>
          </td>
          <td>
            <span>{{order.dailyMenuForDate | date: 'EEEE, dd/MM/yyyy'}}</span>
          </td>
          <td style="white-space: nowrap;">{{order.time}}</td>
          <td>
            <div class="d-flex flex-column">
              <span>{{order.buildingName}}</span>
              <div class="sb-text-cancel d-flex flex-column">
                <span *ngIf="order.blockNumber">Block: {{order.blockNumber}}</span>
                <span *ngIf="order.floor">Floor: {{order.floor}}</span>
                <span *ngIf="order.unitNumber">Unit: {{order.unitNumber}}</span>
              </div>
            </div>
          </td>
          <td class="p-0">
            <table class="table mb-0 table-borderless order-item-table">
              <tbody *ngFor="let orderItem of order.orderItems"
                     [ngClass]="{'table-danger': orderItem.status === 'cancelled'}">
              <tr>
                <td>{{orderItem.quantity}} x <a [routerLink]="['/food-item/view/',orderItem.foodItemId]"
                                                [ngStyle]="{'text-decoration': orderItem.status === 'cancelled' ? 'line-through' : 'none'}">{{
                  orderItem.name}}</a>
                </td>
                <td><a [routerLink]="['/supplier/details',orderItem.supplierId]">{{ orderItem.supplierName}}</a></td>
              </tr>
              </tbody>
            </table>
          </td>
          <td>{{currency}}{{order.totalAmount}}</td>
          <td>
            <div class="sb-tag mb-2" [ngClass]="{
              'sb-tag-pending': order.status==='pending' || order.status==='processing',
              'sb-tag-accepted': order.status==='accepted',
              'sb-tag-overdue': order.status==='overdue',
              'sb-tag-delivered': order.status==='shipped' || order.status==='delivered'
            }">{{order.status.replace('-', '')}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
</div>
