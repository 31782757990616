import {Pipe, PipeTransform, Injectable} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filter',
  pure: false
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string | boolean): any[] {
    if (!items) {
      return [];
    }

    return _.filter(items, it => it[field] === value);
  }
}
