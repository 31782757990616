<mat-form-field appearance="outline">
  <mat-label *ngIf="data">{{title}}</mat-label>
  <mat-label *ngIf="!data">...</mat-label>
  <input matInput [matAutocomplete]="auto" [formControl]="ctrl" [disabled]="!data">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredArray | async" [value]="item[matchKey]" (onSelectionChange)="select(item)">
      {{item[showKey]}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
