import {Injectable} from '@angular/core';
import {OrderItem, Response} from '@app/app.definitions';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {environment} from '#env';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OrderService {

  apiUrl: string = environment.apiUrl;
  private componentApiUrl = this.apiUrl + '/order';
  orderItems: OrderItem[];

  constructor(private http: HttpClient) {
  }

  /**
   * Cancel entire order
   * @param order
   * @param reason
   * @returns {Observable<any>}
   */
  cancelOrder(order, reason): Observable<any> {
    return this.http.patch(this.componentApiUrl + '/cancel-order', {_id: order._id, reason: reason});
  }

  /**
   * Only cancel one order item
   * @param orderItem
   * @param reason
   * @returns {Observable<any>}
   */
  cancelOrderItem(orderItem, reason): Observable<any> {
    return this.http.patch(this.componentApiUrl + '/cancel-order-item', {id: orderItem._id, reason: reason});
  }

  /**
   * Retrieve cancelled orders
   * @param {string} orderForDate
   * @returns {Observable<any>}
   */
  listCancelled(orderForDate: string = null,orderToDate: string = null, options = {}): Observable<any> {
    let params = new HttpParams();

    _.forEach(options, (value, key) => {
      params = params.append(key, value);
    });
    return this.http.get(this.componentApiUrl + '/list-cancelled' + (orderForDate ? '/' + orderForDate : '')+ (orderToDate ? '/' + orderToDate : ''), {params});
  }

  /**
   * Check if Invoice Paid
   * @param order
   * @param reason
   * @returns {Observable<any>}
   */
  getInvoicePaymentStatus(orderId: string): Observable<any> {
    return this.http.get(this.componentApiUrl + '/check-invoice-status/supplierId/' + '?orderId=' + orderId );
  }

  getNewOrders(orderForDate: string = null, orderToDate: string = null, options = {}, isDashboard: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();

      _.forEach(options, (value, key) => {
        params = params.append(key, value);
      });

      return this.http.get(this.componentApiUrl + '/new' + (orderForDate ? '/' + orderForDate : '')
        + (orderToDate ? '/' + orderToDate : '') + (isDashboard ? '/' + isDashboard : ''), {params}).subscribe(
        response => resolve(response),
        error => reject(error));
    });
  }

  getOrderSummary(orderForDate: string = null, orderToDate: string = null, options = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();

      _.forEach(options, (value, key) => {
        params = params.append(key, value);
      });

      return this.http.get(this.componentApiUrl + '/summary' + (orderForDate ? '/' + orderForDate : '')
        + (orderToDate ? '/' + orderToDate : ''), {params}).subscribe(
        response => resolve(response),
        error => reject(error));
    });
  }

  getPickupSummary(orderForDate: string = null, orderToDate: string = null, options = {}): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();

      _.forEach(options, (value, key) => {
        params = params.append(key, value);
      });

      return this.http.get(this.componentApiUrl + '/pickup-summary' + (orderForDate ? '/' + orderForDate : '')
        + (orderToDate ? '/' + orderToDate : ''), {
        params: params
      }).subscribe(
        (response: Response) => {
          const items = response?.data?.suppliersSummary || [];
          resolve(items);
        },
        error => reject(error));
    });
  }

  getUpcomingCaterings(): Observable<any> {
    return this.http.get(this.componentApiUrl + '/caterings/upcoming');
  }

  getPastCaterings(): Observable<any> {
    return this.http.get(this.componentApiUrl + '/caterings/past');
  }

  public SupplierInfo(orders: Array<any>): Object {
    const suppInfo = {};
    orders.forEach((itm: any) => {
      if (!suppInfo[itm.buildingId]) {
        suppInfo[itm.buildingId] = [];
      }
      itm.orderItems.forEach((subItem) => {
        const exist = suppInfo[itm.buildingId].findIndex(function (el) {
          return el.id === subItem.supplierId;
        });
        if (exist < 0) {
          suppInfo[itm.buildingId].push({
            id: subItem.supplierId,
            name: subItem.supplierName
          });
        }
      });
    });
    return suppInfo;
  }

  public GetAssignmentMap(orders: Array<any>, assignments: Array<any>): Object {
    const map = {};
    orders.forEach((itm) => {
      itm.orderItems.forEach(function (subItem) {
        const existDriverIndex = assignments.findIndex(function (el) {
          return el.supplierId === subItem.supplierId && el.buildingId === itm.buildingId;
        });
        if (existDriverIndex >= 0) {
          if (!map[itm.buildingId + subItem.supplierId]) {
            map[itm.buildingId + subItem.supplierId] = {};
          }
          map[itm.buildingId + subItem.supplierId] = assignments[existDriverIndex];
        }
      });
    });
    return map;
  }

  listFeedbacks(offset = 0, limit = 25, dateStart: string, dateEnd?: string) {
    const params = new HttpParams()
    .set('offset', _.toString(offset)).set('limit', _.toString(limit))
    .set('dateStart', dateStart)
    .set('dateEnd', dateEnd ? dateEnd : dateStart);

    return this.http.get(this.componentApiUrl + '/feedbacks', {params});
  }

  saveFeedback(orderId: string, feedbackHandled: boolean) {
    return this.http.patch(this.componentApiUrl + '/feedbacks', {orderId, feedbackHandled});
  }

  /**
   * Update Order
   * @returns {Promise<any>}
   * @param orderId
   * @param item
   */
  updateOrderItem(orderId, item): Observable<any> {
    return this.http.patch(this.componentApiUrl + '/' + orderId, item);
  }

  /**
   * Update Order
   * @returns {Promise<any>}
   * @param orderId
   * @param item
   */
  updateOrderDeliveryType(orderIds=[], status): Observable<any> {
    return this.http.patch(this.componentApiUrl+'/order-delivery-type' , {orderIds,status});
  }

  /**
   * Re Order
   * @returns {Promise<any>}
   * @param orderId
   * @param item
   */
  reOrder(orderId, item): Observable<any> {
    return this.http.post(this.componentApiUrl + '/reorder/' + orderId, item);
  }

  /**
   * Create new Order
   * @returns {Promise<any>}
   * @param item
   */
  createNewOrderItem(item): Observable<any> {
    return this.http.post(this.componentApiUrl + '/new', item);
  }


  uploadFile(_id: string, data: any): Observable<any> {

    // let headers = new HttpHeaders();
    // const token = this.utilService.get('token');
    // headers = headers.append('bypasssecurity', 'true');

    // if (token) {
    //   headers = headers.append('x-access-token', token);
    // }
    return this.http.post(`${this.apiUrl}/corporate/${_id}/file-upload`, data, {});
  }

  /**
   * Update OrderItem
   * @returns {Promise<any>}
   * @param orderItemId
   * @param item
   */
  updateItemInOrder(orderItemId, item): Observable<any> {
    return this.http.patch(this.componentApiUrl + '/order-item/' + orderItemId, item);
  }

  getOrder(orderId: string, isEdit = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('isEdit', isEdit.toString());
      return this.http.get(this.componentApiUrl + '/' + orderId, isEdit ? {params} : {}).subscribe(
        response => resolve(response),
        error => reject(error));
    });
  }

  checkChangeItemSupplier(supplierId: string): Observable<any> {
    return this.http.get(this.componentApiUrl + '/order-item/' + supplierId + '/check-branches');
  }

  changeItemSupplier(orderItemId: string, supplierId: string, supplierName: string): Observable<any> {
    return this.http.post(this.componentApiUrl + '/order-item/' + orderItemId + '/update-branch', {supplierId, supplierName});
  }

  generateOrderInvoice(orderId: string, type: 'invoice' | 'quotation', options = {}): Observable<any> {
    let params = new HttpParams().set('type', type);
    _.forEach(options, (v, k) => {
      if (v) {
        params = params.set(k, v);
      }
    });

    const req = new HttpRequest('GET', this.componentApiUrl + '/invoice/' + orderId, {
      reportProgress: true,
      params
    });

    return this.http.request(req);
  }

  generatePurchaseOrder(orderId: string, supplierId: string,refundReceipt='false'): Observable<any> {
    const params = new HttpParams().set('supplierId', supplierId).set('refundReceipt', refundReceipt);
    const req = new HttpRequest('GET', this.componentApiUrl + '/purchase-order/' + orderId, {
      reportProgress: true,
      params
    });

    return this.http.request(req);
  }

  getProof(orderId: string, supplierId: string,refundReceipt='false',bucket,key): Observable<any> {
    const params = new HttpParams().set('supplierId', supplierId).set('refundReceipt', refundReceipt)
    .set('refundProof', 'true')
    .set('bucket', bucket)
    .set('key', key);

    const req = new HttpRequest('GET', this.componentApiUrl + '/purchase-order/' + orderId, {
      reportProgress: true,
      params
    });

    return this.http.request(req);
  }

  getOrderInvoiceNumber(orderId: string): Observable<any> {
    return this.http.get(this.componentApiUrl + '/invoice-number/' + orderId);
  }

  saveOrCreateQuotation(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/quotation', data);
  }

  deleteQuotation(quotationId: string): Observable<any> {
    return this.http.delete(this.apiUrl + '/quotation/' + quotationId);
  }

  getAllQuotations(offset: number, limit: number) {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());
    return this.http.get(this.apiUrl + '/quotations', {params});
  }

  getQuotation(quotationNumber: string) {
    return this.http.get(this.apiUrl + '/quotation/' + quotationNumber);
  }

  // CSV Order Export
  exportCSVOrders(orderForDate: string = null, orderToDate: string = null, options = {}, isDashboard: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();

      _.forEach(options, (value, key) => {
        params = params.append(key, value);
      });

      return this.http.get(this.componentApiUrl + '/export-csv' + (orderForDate ? '/' + orderForDate : '')
        + (orderToDate ? '/' + orderToDate : '') + (isDashboard ? '/' + isDashboard : ''), {params}).subscribe(
        response => resolve(response),
        error => reject(error));
    });
  }

  // CSV Order Export
  exportCSVChildOrders(parentId: string ): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      return this.http.get(this.componentApiUrl + '/export-childorder-csv' + (parentId ? '/' + parentId : '')
        , {params}).subscribe(
        response => resolve(response),
        error => reject(error));
    });
  }

  getCorporateOrders(limit: number, offset: number, search) {
    const params = new HttpParams().set('limit', limit.toString()).set('offset', offset.toString()).set('search', search);
    return this.http.get(this.apiUrl + '/order/corporate', {params});
  }

  getOrderHistory(id: string,offset: number = 0, limit: number = 20): Observable<any> {
    const params = new HttpParams().set('limit', limit.toString()).set('offset', offset.toString());
    return this.http.get(this.componentApiUrl + `/${id}/history`, {params});
 
  }
  getFields(corporateId): Observable<any> {
    const params = new HttpParams().set('corporateId', corporateId);
    return this.http.get(this.apiUrl + `/custom-fields`,{
      params
    });
  }
}
