import {Component} from '@angular/core';
import {AuthenticationService} from '@app/services/authentication.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {
  username: any;

  constructor(private auth: AuthenticationService) {
    this.username = this.auth.getUser();
  }

  goBack() {
    window.history.back();
  }
}
