import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UntypedFormControl} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

export const MY_FORMATS = {
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DatepickerComponent implements OnInit {
  @Input('placeholder') public placeholder = 'Choose a date';
  @Output('onChange') public onChange = new EventEmitter<string>();
  @Input('reroute') public reroute: string = null;
  @Input('readlocation') public readlocation = true;
  @Input('value') public set value (value) {
    this._value = null;
    this.datex.setValue(moment(new Date(value)));
  }
  private _value: any = null;

  public datex = new UntypedFormControl(moment(new Date()));

  constructor(
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
    if (this.readlocation) {
      this.route.params.subscribe(data => {
        if (data.date) {
          this.datex.setValue(moment(data.date));
          this.dateChange();
        }
      });
    }
    if (this.value) {
      this.datex.setValue(moment(this.value));
    }
    this.dateChange();
  }

  public dateChange() {
    setTimeout( () => {
      const date = moment(this.datex.value).format('YYYY-MM-DD');
      if (this.reroute) {
        this.location.replaceState(this.reroute.replace(':date', date));
      }
      this.onChange.emit(date);
    }, 10);
  }

  public Prev () {
    this.datex.setValue(moment(this.datex.value).subtract('1', 'days'));
    this.dateChange();
  }
  public Next () {
    this.datex.setValue(moment(this.datex.value).add('1', 'days'));
    this.dateChange();
  }
}
