import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@app/services/authentication.service';
import {InterchangeService} from '@app/services/interchange.service';
import {filter} from 'rxjs/operators';
import {SettingsService} from '@app/services/settings.service';
import {RequestsService} from '#req';

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  public WindowTitle: string = null;

  private _router: Subscription;

  url: string;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  sidePanelOpened;
  user;
  username = '';
  mobile = false;
  menuItems: any[];

  @ViewChild('sidemenu', {static: true}) sidemenu;

  constructor(private router: Router,
              public settingsService: SettingsService,
              public auth: AuthenticationService,
              private interchange: InterchangeService,
              private requests: RequestsService) {
    this.username = this.getUser();
    this.menuItems = this.auth.viewableMenuItems();
  }

  ngOnInit() {
    this.interchange.GetEmitter().subscribe(title => {
      this.WindowTitle = title;
    });
    this.url = this.router.url;
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      document.querySelector('.app-inner .mat-drawer-content').scrollTop = 0;
      this.url = event.url;
      if (this.mobile && this.sidemenu) {
        this.sidemenu.close();
      }
    });
    this.mobile = window.matchMedia(`(max-width: 960px)`).matches;
  }

  ngOnDestroy(): void {
    this._router.unsubscribe();
  }

  logout() {
    this.router.navigateByUrl('login');
    this.auth.logout();
  }

  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }

  parseMenuState(menuState) {
    return ['/', ...menuState.split('/')];
  }

  getUser(): string {
    const user = this.auth.getUser();
    if (!user) {
      return null;
    }

    if (user.firstname && user.lastname) {
      return this.getAdminDisplayName(user);
    } else {
      return user['name']
    }
  }

  private getAdminDisplayName(user: any): string {
    return user.firstname + ' ' + user.lastname;
  }

  setNotificationsAsRead(_id: string) {
    this.requests.setNotificationsAsRead(_id).subscribe((response) => {
      if (!response.success) return;
      this.settingsService.notifications = response.data.notifications;
    })
  }
}
