import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '#defs';
import * as _ from 'lodash';
import { MenuItems } from '@shared/menu-items/menu-items';
import { CryptoService } from '@app/core/services/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private capabilities: Array<string> = [];
  private user: User = null;
  private subject = new Subject<any>();
  public token: string;

  constructor(private menuItems: MenuItems, private cryptoService: CryptoService) {
    this.RestoreFromStorage();
  }

  private RestoreFromStorage() {
    try {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.token = JSON.parse(localStorage.getItem('userToken'));
      const userCapabilities = this.GetCapabilities();
      this.capabilities = (userCapabilities ?? []);
    } catch (error) {
      console.error('Error on restoring user:', error);
    }
  }

  public GetToken(): string {
    return this.token;
  }

  public SetCapabilities(items: Array<string>) {
    this.capabilities = items;
    localStorage.setItem('userCapabilities',this.cryptoService.encryptData(JSON.stringify(items)));
  }

  public GetCapabilities(): Array<string> {
    return JSON.parse(this.cryptoService.decryptData(localStorage.getItem('userCapabilities')));
  }

  public SetUser(user) {
    this.user = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public SetToken(token: string) {
    this.token = token;
    localStorage.setItem('userToken', JSON.stringify(token));
  }

  public userCan(key: string): Boolean {
    return this.hasSuperAccess() || this.getCapabilities().includes(key);
  }

  public hasSuperAccess(): Boolean {
    return this.getCapabilities().includes('~super:access');
  }

  public getUser() {
    return this.user;
  }

  public getCapabilities() {
    return this.capabilities;
  }

  public canViewMenuItem(menuItem: any) {
    if (!menuItem.canActivate) {
      return true;
    }

    const exact = this.getCapabilities().includes(menuItem.canActivate);
    const superAccess = this.hasSuperAccess();

    let parent = false;
    if (menuItem.type === 'sub' && menuItem.children) {
      const capabilities = _.uniq(_.map(this.getCapabilities(), c => {
        return c.split(':')[0];
      }));
      parent = capabilities.includes(menuItem.canActivate.split(':')[0]);
    }

    return exact || parent || superAccess;
  }

  public viewableMenuItems(menuItems?) {
    const items = menuItems || this.menuItems.getAll();
    return _.filter(items, m => {
      if (m.type === 'sub' && m.children) {
        m.children = this.viewableMenuItems(m.children);
        return this.canViewMenuItem(m) && m.children && m.children.length;
      }

      return this.canViewMenuItem(m);
    });
  }

  public getHomePage(menuItems?: any[]) {
    const visibleMenuItems = menuItems || this.viewableMenuItems();
    const item = visibleMenuItems[0];
    const url = '/' + item?.state;
    return url + (item?.children ? this.getHomePage(item?.children) : '');
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userToken');
    localStorage.removeItem('userCapabilities');
    this.subject.next(true);
  }
}
