export default [
  {
    name: 'Home',
    items: [
      {
        key: 'home:view',
        name: 'View'
      },
      {
        key: 'home:view:list-of-all-order',
        name: 'View list of all orders'
      },
      {
        key: 'home:view:supplier-summary',
        name: 'View supplier summary'
      },
      {
        key: 'home:view:list-of-feedback',
        name: 'View list of feedback'
      },
      {
        key: 'home:view:cancelled-orders',
        name: 'View cancelled orders'
      },
      {
        key: 'home:view:financial-data',
        name: 'View financial data'
      }
    ]
  },
  {
    name: 'Upcoming Order',
    items: [
      {
        key: 'upcoming-order:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'Operations',
    items: [
      {
        key: 'operations:view',
        name: 'View'
      },
      {
        key: 'operations:logistics-settings:view',
        name: 'Logistics settings'
      },
      {
        key: 'operations:refund:view',
        name: 'Refund'
      },
      {
        key: 'operations:penalties:view',
        name: 'Penalties'
      },
      {
        key: 'operations:deliveryTracking:view',
        name: 'Delivery Tracking 3pl'
      },
      {
        key: 'operations:penalties:action',
        name: 'Action on Penalties'
      },
    ]
  },
  {
    name: 'Dashboard',
    items: [
      {
        key: 'dashboard:view',
        name: 'View'
      }
    ]
  },
  // {
  //   name: 'Quotation',
  //   items: [
  //     {
  //       key: 'quotation:view',
  //       name: 'View'
  //     }
  //   ]
  // },
  {
    name: 'Quotation',
    items: [
      {
        key: 'quotation:quotation:view',
        name: 'Quotation'
      },
      {
        key: 'quotation:concierge:view',
        name: 'Concierge'
      },
    ]
  },
  // {
  //   name: 'Daily Menu',
  //   items: [
  //     {
  //       key: 'dailymenu:view',
  //       name: 'View'
  //     },
  //     {
  //       key: 'dailymenu:list:view',
  //       name: 'View List'
  //     },
  //     {
  //       key: 'dailymenu:items:view',
  //       name: 'View Item'
  //     }
  //   ]
  // },
  // {
  //   name: 'Catering',
  //   items: [
  //     {
  //       key: 'caterings:view',
  //       name: 'View Caterings'
  //     },
  //     {
  //       key: 'caterings:list:view',
  //       name: 'View List'
  //     },
  //     {
  //       key: 'caterings:inquiries:view',
  //       name: 'View Inquiries'
  //     }
  //   ]
  // },
  {
    name: 'Map',
    items: [
      {
        key: 'map:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'Corporate',
    items: [
      {
        key: 'corporate:view',
        name: 'View Corporate'
      },
      {
        key: 'corporate:add',
        name: 'Add new'
      },
      {
        key: 'corporate:item:view',
        name: 'View Corporate Details'
      },
      {
        key: 'corporate:changeCorporateCreditLimit:edit',
        name: 'Change Corporate Credit Limit'
      },
      {
        key: 'corporate:bulkDelete',
        name: 'Bulk Delete'
      },
      {
        key: 'corporate:slotLimitValidation:edit',
        name: 'Slot Limit Validation'
      },
    ]
  },
  {
    name: 'Finance',
    items: [
      {
        key: 'finance:vendor:view',
        name: 'Vendor'
      },
      {
        key: 'finance:corporate:view',
        name: 'Corporate'
      },
      {
        key: 'finance:refund:view',
        name: 'Refund'
      },
      {
        key: 'finance:canteen-top:view',
        name: 'SmartCanteen TopUp'
      }
    ]
  },
  {
    name: 'HelpCenter',
    items: [
      {
        key: 'helpcenter:helpcenter:view',
        name: 'HelpCenter'
      },
      {
        key: 'helpcenter:categories:view',
        name: 'Categories'
      }
    ]
  },
  {
    name: 'Suppliers',
    items: [
      {
        key: 'supplier:view',
        name: 'View'
      },
      {
        key: 'supplier:bulkDelete',
        name: 'Bulk Delete'
      },
      {
        key: 'supplier:bulkUpdate',
        name: 'Bulk Update'
      },
      {
        key: 'supplier:list:view',
        name: 'View List'
      },
      {
        key: 'pending_actions:view',
        name: 'View pending menu modifications'
      },
      {
        key: 'supplier:addMaxPaxLimit',
        name: 'Edit max pax limit per day'
      },
      {
        key: 'supplier:summary:view',
        name: 'View Summary'
      },
      {
        key: 'supplier:details:view',
        name: 'View Details'
      },
      {
        key: 'supplier:add',
        name: 'Add new'
      },
      {
        key: 'supplier:item:view',
        name: 'View Item'
      },
      {
        key: 'supplier:item:view',
        name: 'Add Supplier Item'
      },
      {
        key: 'supplier:promotions:view',
        name: 'View Promotions'
      },
      {
        key: 'supplier:import:view',
        name: 'View Import'
      },
      {
        key: 'supplier:chains:view',
        name: 'View Chain'
      },
      {
        key: 'supplier:item:view',
        name: 'View List-Custom'
      },
      {
        key: 'supplier:promotions:item:view',
        name: 'View Promotions Item'
      },
      {
        key: 'supplier:ingredients:view',
        name: 'View Ingredients'
      },
      {
        key: 'supplier:scassignments:view',
        name: 'SC Assginments'
      },
      {
        key: 'supplier:sclimit:view',
        name: 'SC Limit Formula'
      },
      {
        key: 'supplier:vendorslotusage:view',
        name: 'Vendor Slot Usage'
      },
      {
        key: 'supplier:financial:edit',
        name: 'Edit Financial Data'
      },
      {
        key: 'supplier:margin:edit',
        name: 'Edit Margin Data'
      },
      {
        key: 'supplier:smartcanteenoperatingradius:edit',
        name: 'Edit Smartcanteen operating radius'
      },
      {
        key: 'supplier:agreementdate:edit',
        name: 'Edit Agreement date'
      },
      {
        key: 'supplier:account:loginas',
        name: 'Allow login as supplier'
      },
      {
        key: 'supplier:importreports:view',
        name: 'Report of Import'
      },
      {
        key: 'supplier:suggestion:view',
        name: 'Vendor Suggestion'
      }
    ]
  },
  {
    name: 'Customers',
    items: [
      {
        key: 'customer:view',
        name: 'View'
      },
      {
        key: 'customer:add',
        name: 'Add new'
      },
      {
        key: 'customer:loginas',
        name: 'Allow login as customer'
      },
      {
        key: 'customer-extras:view',
        name: 'Customer extras'
      }
    ]
  },
  {
    name: 'Buildings',
    items: [
      {
        key: 'building:view',
        name: 'View'
      },
      {
        key: 'building:list:view',
        name: 'View List'
      },
    ]
  },
  // {
  //   name: 'Top Up',
  //   items: [
  //     {
  //       key: 'topup:view',
  //       name: 'View'
  //     },
  //   ]
  // },
  {
    name: 'Orders',
    items: [
      {
        key: 'order:view',
        name: 'View'
      },
      {
        key: 'order:edit',
        name: 'Edit'
      },
      {
        key: 'order:edit-all',
        name: 'Edit All'
      },
    ]
  },
  // {
  //   name: 'Url Shortner',
  //   items: [
  //     {
  //       key: 'pxl:view',
  //       name: 'View'
  //     },
  //     {
  //       key: 'pxl:edit',
  //       name: 'Edit'
  //     },
  //     {
  //       key: 'pxl:edit-all',
  //       name: 'Edit All'
  //     },
  //   ]
  // },
  {
    name: 'Refund',
    items: [
      {
        key: 'refund:create',
        name: 'Create'
      },
    ]
  },
  {
    name: 'Delivery View',
    items: [

      {
        key: 'dailydelivery:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'Promo Codes',
    items: [
      {
        key: 'promo:view',
        name: 'View'
      },
      {
        key: 'promo:list:view',
        name: 'Modify Promo Codes'
      },
      {
        key: 'promo:modify',
        name: 'Modify Promo Codes'
      }
    ]
  },
  {
    name: 'New Sign Ups',
    items: [
      {
        key: 'signup:vendor:view',
        name: 'Vendor'
      },
      {
        key: 'signup:corporate:view',
        name: 'Corporate'
      }
    ]
  },
  // {
  //   name: 'Loyalty',
  //   items: [
  //     {
  //       key: 'loyalty:view',
  //       name: 'View'
  //     }
  //   ]
  // },
  {
    name: 'Feedbacks',
    items: [
      {
        key: 'feedbacks:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'FAQs',
    items: [
      {
        key: 'faq:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'terms',
    items: [
      {
        key: 'term:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'Careers',
    items: [
      {
        key: 'career:view',
        name: 'View'
      },
      {
        key: 'career:modify',
        name: 'Modify Career'
      }
    ]
  },
  {
    name: 'Administration',
    items: [
      {
        key: 'admin:view',
        name: 'View '
      },
      {
        key: 'admin:user:view',
        name: 'Users List'
      },
      {
        key: 'admin:user:modify',
        name: 'Modify Users'
      },
      {
        key: 'admin:role:view',
        name: 'System Roles'
      },
      {
        key: 'admin:role:modify',
        name: 'Modify Roles'
      }
    ]
  },
  {
    name: 'Settings',
    items: [
      {
        key: 'settings:view',
        name: 'View'
      }
    ]
  },
  {
    name: 'Export',
    items: [
      {
        key: 'export:reports',
        name: 'Export Reports'
      }
    ]
  },
  {
    name: 'Billing',
    items: [
      {
        key: 'orders:bill',
        name: 'Tick/Un-tick billing'
      }
    ]
  }
  // {
  //   name: 'KPI',
  //   items: [
  //     {
  //       key: 'kpi:view',
  //       name: 'General'
  //     },
  //     {
  //       key: 'kpi:otp:view',
  //       name: 'OTP'
  //     }
  //     ]
  // }
];
