import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '#env';
import {Setting, Response, SubTimeSlot, Notification, DefaultFeatureSetting} from '#defs';
import * as _ from 'lodash';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';

const API = (affix) => environment.apiUrl + '/' + affix;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _slots: Setting['slots'];
  private _suppliers: Setting['suppliers'];
  private _updatedAt: Date;
  private _savedBy: string;
  private _topups: any;
  private _feature: Setting['features'];
  private _subscriptions: any;
  private _settings: any;
  private _notifications: Notification[];
  private _deliverySettings: any;
  public topupsSource: MatTableDataSource<any>;
  public subscriptionsSource: MatTableDataSource<any>;

  get settings(): any {
    return this._settings;
  }

  set settings(value: any) {
    this._settings = value;
  }

  get topups(): any {
    return this._topups;
  }

  set topups(value: any) {
    this._topups = value;
  }

  get subscriptions(): any {
    return this._subscriptions;
  }

  set subscriptions(value: any) {
    this._subscriptions = value;
  }

  get notifications(): Notification[] {
    return this._notifications;
  }

  set notifications(value: Notification[]) {
    const admin = this.auth.getUser();
    this._notifications = _.map(value, v => {
      const isRead = _.includes(v.read, admin._id);
      return {...v, isRead};
    });
  }

  get slots(): { lunch: { '0': SubTimeSlot; '1': SubTimeSlot; '2': SubTimeSlot }; dinner: { '0': SubTimeSlot; '1': SubTimeSlot } } {
    return this._slots;
  }

  set slots(value: { lunch: { '0': SubTimeSlot; '1': SubTimeSlot; '2': SubTimeSlot }; dinner: { '0': SubTimeSlot; '1': SubTimeSlot } }) {
    this._slots = value;
  }

  get suppliers(): { maxPaxSlot: number; sendNewOrdersNotifications: boolean } {
    return this._suppliers;
  }

  set suppliers(value: { maxPaxSlot: number; sendNewOrdersNotifications: boolean }) {
    this._suppliers = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get savedBy(): string {
    return this._savedBy;
  }

  set savedBy(value: string) {
    this._savedBy = value;
  }

  get features(): any {
    return this._feature;
  }

  set features(value: any) {
    this._feature = value;
  }

  private get _router() {
    return this._injector.get(Router);
  }

  get deliverySettings(): any {
    return this._deliverySettings;
  }

  set deliverySettings(value: any) {
    this._deliverySettings = value;
  }

  constructor(private http: HttpClient,
              private _injector: Injector,
              private auth: AuthenticationService) {
  }

  public async getSettings(): Promise<any> {
    if (this.settings) {
      return this.settings;
    }

    return new Promise((resolve, reject) => {
      this.http.get(API('settings')).subscribe((response: Response) => {
        this.handleSettings(response.data);
        resolve(response.data);
      },error => {
        this._router.navigate(['login'], {queryParams: {error: 'Your session has expired. Please log in again.'}});
        resolve(this.auth.logout);
      })
    })
  }

  public saveSettings(settings: object, callback?: any) {
    this.http.post(API('settings'), {settings: settings}).subscribe((response: Response) => {
        if (!response.data || _.isEmpty(response.data.newSettings)) {
          console.error('Received wrong settings format from the server');
          if(callback){
            callback(response)
          }
          return;
        }
        if(callback) {
          callback(response)
        }
        this.handleSettings(response.data.newSettings);
      }
    );
  }

  handleSettings({settings, notifications}) {
    this.settings = settings || {};
    this.updatedAt = settings.updatedAt;
    this.savedBy = settings.savedBy;
    this.slots = settings.slots;
    this.suppliers = settings.suppliers;
    this.topups = settings.topups;
    this.subscriptions = settings.subscriptions;
    this.notifications = notifications || [];
    this.topupsSource = new MatTableDataSource(settings.topups);
    this.subscriptionsSource = new MatTableDataSource(settings.subscriptions);
    this.features = {
      ...DefaultFeatureSetting, ...(this.settings && this.settings.features ? this.settings.features || {} : {})
    };
    this.deliverySettings = {
      autoBookDelivery: this.settings.autoBookDelivery,
      deliveryFeeBase: this.settings.deliveryFeeBase,
      deliveryFeeMinRadius: this.settings.deliveryFeeMinRadius,
      deliveryFeePerKm: this.settings.deliveryFeePerKm,
      mDeliveryFee: this.settings.mDeliveryFee,
      freeDeliveryAboveXAmount: this.settings.freeDeliveryAboveXAmount,
      enablePickup: this.settings.enablePickup,
      allowDeliveryBooking: this.settings.allowDeliveryBooking,
      deliverySetting: this.settings.deliverySetting,
      autoBookDeliveryOption: this.settings.autoBookDeliveryOption,
      deliveryFeeOption: this.settings.deliveryFeeOption,
      deliveryFeePercentage: this.settings.deliveryFeePercentage,
      deliveryFeeBaseTypes: this.settings.deliveryFeeBaseTypes
    }

    return this.settings;
  }
}
