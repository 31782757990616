import {Pipe, PipeTransform, Injectable} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'map',
  pure: false
})
@Injectable()
export class MapPipe implements PipeTransform {
  transform(items: any[], field: string, joinBy: string = undefined): any[] | string {
    if (!items) {
      return [];
    }

    const map = _.uniq(_.map(items, it => it[field]));
    if(joinBy){
      return (map || []).join(joinBy);
    }
    return (map || []);
  }
}
