import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private auth: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot): any {
    const $c = route.data && route.data.$c || null;
    if ($c) {
      const access = $c.every(permission => this.auth.userCan(permission));
      if (!access) {
        this.router.navigate(['/access-denied']);
        return;
      }
    }

    if (!this.auth.getUser()) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.canActivate(childRoute);
  }
}
