import {Component, OnInit} from '@angular/core';
import {Order} from '#defs';
import {OrderService} from '@app/services/order.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {SettingsService} from '@app/services/settings.service';
import { environment } from '#env';

@Component({
  selector: 'app-upcoming-orders',
  templateUrl: './upcoming-orders.component.html',
  styleUrls: ['./upcoming-orders.component.scss']
})
export class UpcomingOrdersComponent implements OnInit {

  orders: Order[] = [];
  loading = false; 
  currency = environment.currency.iso3;
  error;

  constructor(private orderService: OrderService, public settings: SettingsService) {
  }

  ngOnInit() {
    this.getOrders();
  }

  getOrders() {
    this.loading = true;
    const options = {
      'paymentStatus': 'paid',
      'limit': 100
    };
    this.orderService.getNewOrders(null, null, options)
      .then(
        results => {
          this.loading = false;
          const items = results.data.items;

          this.orders = _.orderBy(items.map((order) => {

            const lastDeliveryStatus = order.deliveryHistory && order.deliveryHistory[0]
              ? _.replace(_.last(order.deliveryHistory)['status'], /_/g, ' ') : '-';

            return {
              ...order,
              time: this.getTime(order),
              lastDeliveryStatus: lastDeliveryStatus
            }
          }), ['dailyMenuForDate'], ['asc']);
        }, e => {
          this.loading = false;
          this.error = e.error.description;
        }
      )
      .catch(e => {
        throw e;
      });
  }

  getTime(item) {
    const {timeSlot, subTimeSlot} = item;
    if (typeof subTimeSlot !== 'undefined') {
      const sts = this.settings.slots[timeSlot][subTimeSlot];
      const time = sts.readyBy.split(':');
      return moment().set('hours', parseInt(time[0], 10)).set('minutes', parseInt(time[1], 10)).format('hh:mm a');
    }
    return moment(item.dailyMenuForDate).format('hh:mm a');
  }
}
