import {Component} from '@angular/core';
import {AuthConfig, JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  // authConfig: AuthConfig = {
  //   issuer: 'https://accounts.google.com',
  //   redirectUri: window.location.origin,
  //   clientId: '135849441272-86likgpsb7q87m51i9e6vj1d19656pcv.apps.googleusercontent.com',
  //   scope: 'openid profile email',
  //   strictDiscoveryDocumentValidation: false
  // };

  constructor() {
    // this.oauthService.configure(this.authConfig);
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
