import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddOn} from '@app/entities/add-on';
import {Customization, OrderItem, Response} from '#defs';
import {FoodCustomItemService} from '@app/services/food-custom-item.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {FoodItemService} from '@app/services/food-item.service';
import {AuthenticationService} from '@app/services/authentication.service';

@Component({
  selector: 'app-order-item-configuration',
  templateUrl: './order-item-configuration.component.html',
  styleUrls: ['./order-item-configuration.component.scss']
})
export class OrderItemConfigurationComponent implements OnInit {

  @Input() item: OrderItem;
  @Input() modificationLevel: number = 0;
  @Input() readonlyFields: boolean = false;
  @Input() newOrder: boolean = false;
  @Output('updateOrderPrice') public updateOrderPrice = new EventEmitter<any>();

  editFinancial: boolean;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link']                         // link and image, video
    ]
  };

  constructor(private foodService: FoodCustomItemService,
              private snackBar: MatSnackBar,
              private foodItem: FoodItemService,
              private authService: AuthenticationService) {
    const capabilities = this.authService.GetCapabilities();
    this.editFinancial = _.includes(capabilities, 'supplier:financial:edit');
  }

  ngOnInit() {
    if (!this.item.foodItemCustoms && this.item.foodItemId) {
      this.foodItem.getItem(this.item.foodItemId).subscribe(
        (response: Response) => {
          if (!response.success) {
            return;
          }
          this.item.foodItemCustoms = _.orderBy(response.data.item.foodItemCustoms, 'index', ['asc']);
        }
      );
    }
    this.item.foodItemCustoms = _.orderBy(this.item.foodItemCustoms, 'index', ['asc']);
  }

  selectAddon(addon: AddOn, item: any, items: any,event) {
    if (event.isUserInput) {
      addon._id = item._id;
      addon.gmv = item.gmv;
      addon.nmv = item.nmv;
      addon.restaurantPrice = item.restaurantPrice;
      addon.marginFromRestaurant = item.marginFromRestaurant;
      addon.nmv_gold = item.nmv_gold;
      addon.gold = item.gold;
      addon.qty = 1;
      addon.total = item.nmv;
      this.changeItemTotal(items);
    }
  }

  addAddon(c: Customization) {
    if (!this.item.addons) {
      this.item.addons = [];
    }
    this.foodService.getCustomItemsForSupplier(this.item.supplierId, null, 1, 999, c.itemType).subscribe(
      (response: Response) => {
        if (!response.success) {
          return this.snackBar.open('Error while retrieving addons.', 'Okay');
        }

        const {items} = response.data;
        this.item.addons.push(new AddOn({
          options: items,
          category: c.itemType,
          supplierId: this.item.supplierId,
          maxAllowed: c.maxAllowed,
          minAllowed: c.minAllowed,
          require: c.minAllowed,
          individualQuantity: c.individualQuantity
        }));
      }
    );
  }

  changeItemTotal(item) {
    item.quantity = item.quantity || 1;
    item.total = item.quantity * item.nmv;
    let addonTotal = 0;
    (item.addons || []).forEach((a) => {
      const diff = a.qty - (a.included || 0);
      a.total = Math.max(diff, 0) * a.nmv * (a.individualQuantity ? 1 : item.quantity)
      addonTotal += a.total;
    })

    item.rewardPointsAmount = (item.supplierLoyaltyPercentage || 0) * (item.nmv + addonTotal);

    // output the order
    this.updateOrderPrice.emit(item);
  }

  deleteAddon(c: any) {
    const index = this.item.addons.findIndex(a => a.category === c.itemType )
    this.item.addons.splice(index, 1);
  }

  changeRestaurantPrice(item) {
    if (!this.editFinancial) {
      this.item.nmv = this.item.gmv;
      this.changeItemTotal(item);
    }
  }

  created(event: any) {
    event.root.innerHTML = this.item.description;
  }
}
