import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '#env';

const API = (affix) => environment.apiUrl + '/notification/' + affix;

interface INotification {
  title: string;
  description: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notes: Array<INotification> = [
    {
      title: 'New offer',
      description: 'Pay 5$ now and user for next 6 months',
      icon: 'local_offer',
    }
  ];

  constructor(private http: HttpClient) {
  }

  get Items(): Array<INotification> {
    return this.notes;
  }

  public sendPushNotification(message: object, supplierIds: string[]) {
    return this.http.post(API('send-push'), { message, supplierIds, tokens: [] });
  }

  public sendDeviceNotification(deviceId: string, title: string, message: string) {
    return this.http.post(API('send-device'), { deviceId, title, message });
  }

}
