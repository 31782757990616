import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {UtilService} from '@app/services/util.service';

@Pipe({
  name: 'userCurrency'
})
export class UserCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe,
              public utilService: UtilService) {
  }

  transform(value: any,
            currencyDigit?: number,
            display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
            digitsInfo?: string,
            locale?: string): string | null {
    const currency = this.utilService.getCurrency(currencyDigit || 3) || 'MYR';
    if (!value) {
      return '0 ' + this.currencyPipe.transform(0, currency + ' ', display).split('0.00')[0];
    }
    return this.currencyPipe.transform(value, currency + ' ', display, '1.2-2');
  }
}
