import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'tableSearchFilter'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }

    return value.filter((val) => {
      var rVal;
      if(val.supplierName && val.corporateName){
        rVal = (val.supplierName.toLocaleLowerCase().includes(args)) ||  (val.corporateName.toLocaleLowerCase().includes(args));
      
      }
      else if(val.supplierName && !val.corporateName){
        rVal = (val.supplierName.toLocaleLowerCase().includes(args));
      }
      else if(!val.supplierName && val.corporateName){
        rVal = (val.corporateName.toLocaleLowerCase().includes(args));
      }

      return rVal;
    })

  }

}