<div class="app mat-typography" [dir]="'ltr'"
     [ngClass]="{'app-dark': dark, 'boxed': boxed, 'collapsed-sidebar': collapseSidebar, 'compact-sidebar': compactSidebar, 'side-panel-opened': sidePanelOpened }">
  <mat-toolbar class="main-header" color="primary">
    <button (click)="drawer.toggle()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <span class="hidden-sm-up">{{WindowTitle}}</span>
    <div class="branding">
      <img src="assets/logo.svg" style="display: block; width: 135px;">
    </div>

    <div style="flex: 1 1 auto;"></div>

    <p class="mb-0 d-none d-sm-block username">
      <span>{{username}}</span>
    </p>

    <button class="ml-xs" [matMenuTriggerFor]="notifications" mat-icon-button>
      <mat-icon *ngIf="settingsService.notifications && (settingsService.notifications | filter:'isRead':false).length"
                [matBadge]="(settingsService.notifications | filter:'isRead':false).length.toString()"
                matBadgeColor="warn">
        notifications
      </mat-icon>
      <mat-icon *ngIf="!settingsService.notifications || !(settingsService.notifications | filter:'isRead':false).length">
        notifications_none
      </mat-icon>
    </button>
    <button class="ml-xs" appToggleFullscreen mat-icon-button>
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button class="ml-xs" [matMenuTriggerFor]="user" mat-icon-button>
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #notifications="matMenu" xPosition="before" [overlapTrigger]="false">
      <mat-list class="pt-0">
        <div mat-subheader>Unread notifications</div>
        <mat-list-item *ngFor="let n of settingsService.notifications | filter:'isRead':false">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <mat-icon class="mr-3" matTooltip="Set as read" (click)="setNotificationsAsRead(n._id)">check_circle
            </mat-icon>
            <div class="d-flex flex-column">
              <h6 class="mb-1">{{n.title}}</h6>
              <p class="mb-1">{{n.description}}</p>
              <small class="text-muted">{{n.createdAt | date:'EEEE, dd MMM yyyy'}}</small>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="!(settingsService.notifications | filter:'isRead':false).length">
          <small class="text-muted">No new notifications</small>
        </mat-list-item>
        <mat-divider class="mt-2"></mat-divider>
        <div mat-subheader>Past notifications</div>
        <mat-list-item *ngFor="let n of settingsService.notifications | filter:'isRead':true">
          <div class="d-flex flex-column">
            <h6 class="mb-1">{{n.title}}</h6>
            <p class="mb-1">{{n.description}}</p>
            <small class="text-muted">{{n.createdAt | date:'EEEE, dd MMM yyyy'}}</small>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="!(settingsService.notifications | filter:'isRead':true).length">
          <small class="text-muted">No past notifications</small>
        </mat-list-item>
      </mat-list>
    </mat-menu>

    <mat-menu #user="matMenu" xPosition="before" [overlapTrigger]="false">
      <span class="visible-xs hidden-sm-up" disabled mat-menu-item style="color: grey">
        {{username}}
      </span>
      <!--<a [routerLink]="'/change-password'" mat-menu-item>-->
      <!--<mat-icon>exit_to_app</mat-icon>-->
      <!--Change Password-->
      <!--</a>-->
      <button (click)="logout();" mat-menu-item *ngIf="username">
        <mat-icon>exit_to_app</mat-icon>
        Sign Out
      </button>
    </mat-menu>
  </mat-toolbar>

  <mat-drawer-container class="app-inner">
    <mat-drawer #drawer class="sidebar-panel" id="sidebar-panel" [mode]="mobile ? 'over' : 'side'"
                [opened]="!mobile && !!username" (opened)="sidePanelOpened = true"
                (closed)="sidePanelOpened = false" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <!-- sidenav content -->
      <mat-nav-list appAccordion class="navigation">
        <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems" [group]="menuitem.state">

          <a appAccordionToggle class="relative" [routerLink]="parseMenuState(menuitem.state)"
             *ngIf="menuitem.type === 'link'">
            <mat-icon>{{menuitem.icon}}</mat-icon>
            <span>{{menuitem.name}}</span>
          </a>

          <a appAccordionToggle class="relative" href="javascript:" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{menuitem.icon}}</mat-icon>
            <span>{{menuitem.name}}</span>
            <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
          </a>
          <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
            <ng-container *ngFor="let childitem of menuitem.children">
              <mat-list-item routerLinkActive="open">
                <a [routerLink]="['/', menuitem.state, childitem.state]"
                   class="relative">{{childitem.name}}</a>
              </mat-list-item>
            </ng-container>
          </mat-nav-list>
        </mat-list-item>
      </mat-nav-list>
    </mat-drawer>


    <div style="height: 100%">
      <router-outlet></router-outlet>
    </div>

  </mat-drawer-container>

</div>
