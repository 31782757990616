<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="text-xs-center pb-1">
              <img src="assets/images/smartbite.svg" alt="smartbite" id="logo" class="mt-3 mb-5"/>
              <div *ngIf="error" class="text-danger mb-0">
                {{ error }}
              </div>
            </div>

            <div class="d-flex flex-column align-content-around">
              <div class="pb-1">
                <mat-form-field appearance="outline">
                  <mat-label>Username</mat-label>
                  <input matInput [formControl]="loginForm.controls['username']">
                </mat-form-field>
                <small class="mat-text-warn" *ngIf="FormError('username')">
                  Error: {{FormError('username')}}
                </small>
              </div>
              <div class="pb-1">
                <mat-form-field style="width: 100%" appearance="outline">
                  <mat-label>Password</mat-label>
                  <input matInput type="password" [formControl]="loginForm.controls['password']">
                </mat-form-field>
                <small class="mat-text-warn" *ngIf="FormError('password')">
                  Error: {{FormError('password')}}
                </small>
              </div>

              <div class="pb-1" *ngIf="enableOtpField">
                <mat-form-field appearance="outline">
                  <mat-label>OTP Code</mat-label>
                  <input matInput [formControl]="loginForm.controls['otpCode']">
                </mat-form-field>
                <small class="mat-text-warn" *ngIf="FormError('otpCode')">
                  Error: {{FormError('otpCode')}}
                </small>
              </div>

              
              <div class="pb-1">
                <mat-checkbox [formControl]="loginForm.controls['stayLogged']">Stay logged in</mat-checkbox>
              </div>
              <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Login</button>
            </div>

          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
