import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTimepickerModule} from 'mat-timepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {NgxDropzoneModule} from 'ngx-dropzone';

import {EditorModule} from '@tinymce/tinymce-angular';
import { QuillModule } from 'ngx-quill'
import {PaginationComponent} from '@app/components/pagination/pagination.component';
import {AutocompleteComponent} from '@app/components/common/autocomplete/autocomplete.component';
import {OrderItemConfigurationComponent} from '@app/components/order-item-configuration/order-item-configuration.component';
import {GroupByPipe} from '@app/pipes/group-by.pipe';
import {SumPipe} from '@app/pipes/sum.pipe';
import {SlugPipe} from '@app/pipes/slug.pipe';
import {CountPipe} from '@app/pipes/count.pipe';
import {SafeHtmlPipe} from '@app/pipes/safe-html';
import {FilterPipe} from '@app/pipes/filter.pipe';
import {FillPipe} from '@app/pipes/fill.pipe';
import {MapPipe} from '@app/pipes/map.pipe';
import {NotFoundComponent} from '@shared/not-found/not-found.component';
import {MenuItems} from '@shared/menu-items/menu-items';
import {InterchangeService} from '@app/services/interchange.service';
import {NotificationService} from '@app/services/notification.service';
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from '@shared/accordion';
import {ToggleFullscreenDirective} from '@shared/fullscreen/toggle-fullscreen.directive';
import {DatepickerComponent} from '@app/components/datepicker/datepicker.component';
import {RatingComponent} from '@shared/rating/rating.component';
import {NavigationComponent} from '@app/navigation/navigation.component';
import {AdminLayoutComponent} from '@app/layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from '@app/layouts/auth/auth-layout.component';
import {LoginComponent} from '@app/components/login/login.component';
import {ConfirmComponent} from '@app/dialogs/confirm/confirm.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MaterialModule} from '@shared/material/material.module';
import {RouterModule} from '@angular/router';
import {JoinPipe} from '@app/pipes/join.pipe';
import {UserCurrencyPipe} from '@app/pipes/currency.pipe';

import {AddDaysPipe} from '@app/pipes/add-days.pipe';

import {ClipboardModule} from 'ngx-clipboard';
import {ColorPickerModule} from 'ngx-color-picker';
import {RatingModule} from 'ng-starrating';
import { SearchPipe } from '@app/pipes/search.pipe';

const commonComponents = [
  SearchPipe,
  PaginationComponent,
  AutocompleteComponent,
  OrderItemConfigurationComponent,
  GroupByPipe,
  SumPipe,
  SlugPipe,
  FillPipe,
  CountPipe,
  SafeHtmlPipe,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
  ToggleFullscreenDirective,
  DatepickerComponent,
  RatingComponent,
  FilterPipe,
  MapPipe,
  JoinPipe,
  NotFoundComponent,
  NavigationComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  LoginComponent,
  ConfirmComponent,
  UserCurrencyPipe,
  AddDaysPipe
];

const commonModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  EditorModule,
  MatSelectModule,
  MatExpansionModule,
  MatTimepickerModule,
  MatDatepickerModule,
  MatChipsModule,
  MatTableModule,
  MatMenuModule,
  MatButtonModule,
  MatTooltipModule,
  MatBadgeModule,
  MaterialModule,
  RouterModule,
  ColorPickerModule,
  NgxDropzoneModule,
  ClipboardModule,
  RatingModule,
  QuillModule
];

// @ts-ignore
@NgModule({
  imports: [...commonModules, QuillModule.forRoot()],
  declarations: commonComponents,
  exports: [...commonComponents, ...commonModules],
  providers: [
    MenuItems,
    TitleCasePipe,
    InterchangeService,
    NotificationService
  ]
})
export class SbCommonModule {
}
