export default [
  {
    state: 'home',
    name: 'Home',
    type: 'link',
    icon: 'home',
    canActivate: 'home:view'
  },
  {
    state: 'upcoming-order',
    name: 'Upcoming Order',
    type: 'link',
    icon: 'home',
    canActivate: 'upcoming-order:view'
  },
  {
    state: 'operations',
    name: 'Operations',
    type: 'sub',
    icon: 'dashboard',
    canActivate: 'operations:view',
    children: [
      {
        state: 'operations',
        name: 'Operations',
        type: 'link',
        icon: 'dashboard',
        canActivate: 'operations:view',

      },
      {
        state: 'logistics-settings',
        name: 'Logistics settings',
        type: 'link',
        icon: 'dashboard',
        canActivate: 'operations:logistics-settings:view',
      },
      {
        state: 'refund',
        name: 'Refund',
        type: 'link',
        icon: 'dashboard',
        canActivate: 'operations:refund:view',
      },
      {
        state: 'penalties',
        name: 'Penalties',
        type: 'link',
        icon: 'dashboard',
        canActivate: 'operations:penalties:view',
      },
      {
        state: 'deliveryTracking',
        name: 'Delivery Tracking',
        type: 'link',
        icon: 'dashboard',
        canActivate: 'operations:deliveryTracking:view',
      }
    ]
  },
  // {
  //   state: 'dashboard',
  //   name: 'Dashboard',
  //   type: 'link',
  //   icon: 'home',
  //   canActivate: 'dashboard:view'
  // },
  {
    state: 'quotation',
    name: 'Quotation',
    type: 'link',
    icon: 'folder_open',
    canActivate: 'quotation:view'
  },
  // {
  //   state: 'daily-menu',
  //   name: 'Daily Menu',
  //   type: 'sub',
  //   icon: 'restaurant_menu',
  //   canActivate: 'dailymenu:view',
  //   children: [
  //     {
  //       name: 'Daily Menu List',
  //       state: '',
  //       type: 'link',
  //       canActivate: 'dailymenu:list:view',
  //     },
  //     {
  //       name: 'Menu Items',
  //       state: 'items',
  //       type: 'link',
  //       canActivate: 'dailymenu:items:view',
  //     }
  //   ]
  // },
  // {
  //   state: 'caterings',
  //   name: 'Caterings',
  //   type: 'sub',
  //   icon: 'group',
  //   canActivate: 'caterings:view',
  //   children: [
  //     {
  //       state: 'list',
  //       name: 'Caterings',
  //       type: 'link',
  //       icon: 'group',
  //       canActivate: 'caterings:list:view'
  //     },
  //     {
  //       state: 'inquiries',
  //       name: 'Inquiries',
  //       type: 'link',
  //       icon: 'settings_input_component',
  //       canActivate: 'caterings:inquiries:view'
  //     }
  //   ]
  // },
  // {
  //   state: 'map',
  //   name: 'Map',
  //   type: 'link',
  //   icon: 'map',
  //   canActivate: 'map:view'
  // },
  {
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    icon: 'business',
    canActivate: 'reports:view',
    children: [
      {
        state: 'import-status',
        name: 'Import Status',
        type: 'link',
        icon: 'business'
      }
    ]
  },
  {
    state: 'cms',
    name: 'CMS',
    type: 'sub',
    icon: 'business',
    canActivate: 'cms:view',
    children: [
      {
        state: 'blog',
        name: 'Blog',
        type: 'link',
        icon: 'business'
      },
      {
        state: 'gallery',
        name: 'Gallery',
        type: 'link',
        icon: 'business'
      }
    ]

  },
  {
    state: 'corporate',
    name: 'Corporate',
    type: 'link',
    icon: 'business',
    canActivate: 'corporate:view'
  },
  {
    state: 'corporate-payments',
    name: 'Corporate Payments',
    type: 'sub',
    icon: 'payment',
    canActivate: 'corporate-payments:view',
    children: [
      {
        state: 'orders',
        name: 'Orders to be confirmed',
        type: 'link',
        canActivate: 'corporate-payments:orders:view',
      },
      {
        state: 'invoices',
        name: 'Invoices to be paid',
        type: 'link',
        canActivate: 'corporate-payments:invoices:view',
      }
    ]
  },

  {
    state: 'quotation',
    name: 'Quotation',
    type: 'sub',
    icon: 'monetization_on',
    canActivate: 'quotation:view',
    children: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'business',
        canActivate: 'quotation:concierge:view'
      },
      {
        state: 'concierge',
        name: 'Request',
        type: 'link',
        icon: 'business',
        canActivate: 'quotation:concierge:view'
      },
      {
        state: 'create',
        name: 'Builder',
        type: 'link',
        icon: 'fastfood',
        canActivate: 'quotation:quotation:view'
      },
      {
        state: 'list',
        name: 'Draft',
        type: 'link',
        icon: 'fastfood',
        canActivate: 'quotation:quotation:view'
      },

    ]
  },
  {
    state: 'finance',
    name: 'Finance',
    type: 'sub',
    icon: 'monetization_on',
    canActivate: 'finance:view',
    children: [
      {
        state: 'vendor',
        name: 'Vendor',
        type: 'link',
        icon: 'fastfood',
        canActivate: 'finance:vendor:view'
      },
      {
        state: 'corporate',
        name: 'Corporates',
        type: 'link',
        icon: 'business',
        canActivate: 'finance:corporate:view'
      },
      {
        state: 'refund',
        name: 'Refunds',
        type: 'link',
        icon: 'business',
        canActivate: 'finance:refund:view'
      },
      {
        state: 'smartcanteen-topup',
        name: 'SmartCanteen TopUp',
        type: 'link',
        icon: 'business',
        canActivate: 'finance:canteen-top:view'
      }
    ]
  },
  {
    state: 'helpcenter',
    name: 'Helpcenter',
    type: 'sub',
    icon: 'help_center',
    canActivate: 'helpcenter:helpcenter:view',
    children: [
      {
        state: 'helpcenter',
        name: 'Helpcenter',
        type: 'link',
        icon: 'fastfood',
        canActivate: 'helpcenter:helpcenter:view'
      },
      {
        state: 'categories',
        name: 'Categories',
        type: 'link',
        icon: 'business',
        canActivate: 'helpcenter:categories:view'
      },
    ]
  },
  {
    state: 'supplier',
    name: 'Suppliers',
    type: 'sub',
    icon: 'fastfood',
    canActivate: 'supplier:view',
    children: [
      {
        name: 'Supplier List',
        state: 'list',
        type: 'link',
        canActivate: 'supplier:list:view',
      },
      {
        name: 'Pending menu modifications',
        state: 'pending-request',
        type: 'link',
        icon: 'pending_actions',
        canActivate: 'pending_actions:view'
      },
      {
        state: 'summary',
        name: 'Supplier Summary',
        type: 'link',
        canActivate: 'supplier:summary:view',
      },
      {
        state: 'promotions',
        name: 'Promotions',
        type: 'link',
        canActivate: 'supplier:promotions:view', // promotions:
      },
      {
        state: 'ingredients',
        name: 'Ingredients',
        type: 'link',
        canActivate: 'supplier:ingredients:view', // ingredients:
      },
      {
        state: 'scassignments',
        name: 'Sc Assignments',
        type: 'link',
        canActivate: 'supplier:scassignments:view', // scassignments:
      },
      {
        state: 'sclimit',
        name: 'SC Limit Algo',
        type: 'link',
        canActivate: 'supplier:sclimit:view', // sclimit:
      },
      {
        state: 'vendorslotusage',
        name: 'Vendor Slot Usage',
        type: 'link',
        canActivate: 'supplier:vendorslotusage:view', // vendorslotusage:
      },
      {
        state: 'chains',
        name: 'Chains',
        type: 'link',
        canActivate: 'supplier:chains:view', // chains:
      },
      {
        name: 'Sign Ups',
        state: 'sign-up',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'supplier:view'
      },
      {
        name: 'Import By Google Sheet',
        state: 'import',
        icon: '',
        type: 'link',
        canActivate: 'supplier:importreports:view'
      },
      {
        name: 'Import By Self',
        state: 'import-by-self',
        icon: '',
        type: 'link',
        canActivate: 'supplier:importreports:view'
      },
      {
        name: 'Import Status',
        state: 'import-status',
        icon: '',
        type: 'link',
        canActivate: 'supplier:importreports:view',
      },
      {
        state: 'suggestion',
        name: 'Vendor suggestion',
        type: 'link',
        canActivate: 'supplier:suggestion:view', // ingredients:
      },
    ]
  },
  {
    state: 'customer',
    name: 'Customers',
    type: 'sub',
    icon: 'nature_people',
    canActivate: 'customer:view',
    children: [
      {
        name: 'Customer List',
        state: '',
        type: 'link',
        canActivate: 'customer:view',
      },
      {
        name: 'Extras',
        state: 'customer-extras',
        type: 'link',
        icon: 'pending_actions',
        canActivate: 'customer-extras:view'
      },
    ]
  },
  {
    state: 'location',
    name: 'Buildings',
    type: 'sub',
    icon: 'place',
    canActivate: 'building:view',
    children: [
      {
        name: 'Building List',
        state: 'building',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'building:list:view'
      },
      {
        name: 'Zone List',
        state: 'zone',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'building:zone:view'
      },
      {
        name: 'Sign Ups',
        state: 'sign-up',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'building:view'
      }
    ]
  },
  // {
  //   state: 'riders',
  //   name: 'Delivery Fleet',
  //   type: 'sub',
  //   icon: 'drive_eta',
  //   canActivate: 'rider:view',
  //   children: [
  //     {
  //       state: 'delivery',
  //       name: 'Delivery Fleet',
  //       type: 'link',
  //       icon: 'drive_eta',
  //       canActivate: 'riders:delivery:view'
  //     },
  //     {
  //       state: 'sign-up',
  //       name: 'Sign Ups',
  //       type: 'link',
  //       icon: 'drive_eta',
  //       canActivate: 'rider:view'
  //     },
  //   ]
  // },
  // {
  //   state: 'sms',
  //   name: 'SMS',
  //   icon: 'textsms',
  //   type: 'sub',
  //   canActivate: 'sms:view',
  //   children: [
  //     {
  //       state: 'incoming',
  //       name: 'Incoming',
  //       type: 'link'
  //     },
  //     {
  //       state: 'bulk',
  //       name: 'Send Bulk',
  //       type: 'link'
  //     }
  //   ]
  // },
  // {
  //   state: 'topup',
  //   name: 'Top Ups',
  //   type: 'sub',
  //   icon: 'monetization_on',
  //   canActivate: 'topup:view',
  //   children: [
  //     {
  //       name: 'Credit Top-up',
  //       state: 'top-up',
  //       type: 'link',
  //       canActivate: 'topup:view'
  //     },
  //     // {
  //     //   name: 'Nuggets Top-up',
  //     //   state: 'nugget',
  //     //   type: 'link',
  //     //   canActivate: 'topup:nugget'
  //     // }
  //   ]
  // },
  // {
  //   state: 'subscriptions',
  //   name: 'Subscriptions',
  //   type: 'link',
  //   icon: 'monetization_on',
  //   canActivate: 'subscriptions:view'
  // },
  // {
  //   state: 'tasksheets',
  //   name: 'Tasksheets',
  //   icon: 'dns',
  //   type: 'link',
  //   canActivate: 'tasksheet:view'
  // },
  // {
  //   state: 'orders',
  //   name: 'Orders Allocation',
  //   type: 'link',
  //   icon: 'shopping_basket',
  //   canActivate: 'order:view'
  // },
  // {
  //   state: 'pxl',
  //   name: 'Url Shortner',
  //   icon: 'gear',
  //   type: 'link',
  //   canActivate: 'pxl:view'
  // },
  {
    state: 'promo',
    name: 'Promo Codes',
    icon: 'card_giftcard',
    type: 'sub',
    canActivate: 'promo:view',
    children: [
      {
        state: 'list',
        name: 'List',
        type: 'link',
        canActivate: 'promo:list:view'
      },
      {
        state: 'create',
        name: 'Create',
        type: 'link',
        canActivate: 'promo:modify',
      }
    ]
  },
  {
    state: 'signup',
    name: 'New Sign Ups',
    type: 'sub',
    icon: 'person_add',
    canActivate: 'signup:view',
    children: [
      {
        state: 'vendor',
        name: 'Vendor',
        type: 'link',
        canActivate: 'signup:vendor:view',
      },
      {
        state: 'corporate',
        name: 'Corporate',
        type: 'link',
        canActivate: 'signup:corporate:view',
      }
    ]
  },
  // {
  //   state: 'loyalty',
  //   name: 'Loyalty',
  //   icon: 'card_giftcard',
  //   type: 'link',
  //   canActivate: 'loyalty:view'
  // },
  {
    state: 'feedbacks',
    name: 'Feedbacks',
    type: 'link',
    icon: 'feedback',
    canActivate: 'feedbacks:view'
  },
  // {
  //   state: 'kpi',
  //   name: 'KPI',
  //   icon: 'timeline',
  //   type: 'sub',
  //   canActivate: 'kpi:view',
  //   children: [
  //     {
  //       state: 'general',
  //       name: 'General',
  //       type: 'link',
  //       canActivate: 'kpi:view'
  //     },
  //     {
  //       state: 'otp',
  //       name: 'OTP',
  //       type: 'link',
  //       canActivate: 'kpi:otp:view'
  //     }
  //   ]
  // },
  {
    state: 'faq',
    name: 'FAQ',
    icon: 'question_answer',
    type: 'link',
    canActivate: 'faq:view'
  },
  {
    state: 'career/list',
    name: 'Career',
    icon: 'business_center',
    type: 'link',
    canActivate: 'career:view'
  },
  {
    state: 'term',
    name: 'T&C',
    icon: 'import_contacts',
    type: 'link',
    canActivate: 'term:view'
  },
  {
    state: 'admin',
    name: 'Administrations',
    type: 'sub',
    icon: 'person_add',
    canActivate: 'admin:view',
    children: [
      {
        name: 'Users List',
        state: 'users',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'admin:user:view'
      },
      {
        name: 'Create User',
        state: 'users-create',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'admin:user:modify'
      },
      {
        name: 'Roles List',
        state: 'roles',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'admin:role:view'
      },
      {
        name: 'Create Role',
        state: 'roles-create',
        icon: 'settings_input_component',
        type: 'link',
        canActivate: 'admin:role:modify'
      }
    ]
  },
  {
    state: 'settings',
    name: 'Settings',
    icon: 'settings',
    type: 'link',
    canActivate: 'settings:view'
  },
  {
    state: 'releases',
    name: 'Releases',
    icon: 'settings_input_component',
    type: 'link',
    canActivate: 'releases:view'
  }
];
