import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from 'environments/environment';
import {LoginComponent} from '@app/components/login/login.component';
import {AuthGuard} from '@app/guards/auth.guard';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {NotFoundComponent} from '@app/shared/not-found/not-found.component';
import {AccessDeniedComponent} from '@app/components/access-denied/access-denied.component';
import {UpcomingOrdersComponent} from '@app/components/upcoming-orders/upcoming-orders.component';
import { BulkUpdateComponent } from './components/bulk-update/bulk-update.component';

export const routes: Routes = [
  { path: '',
    redirectTo: 'home',
    pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'access-denied',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: AccessDeniedComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'home',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'operations',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/operations.module').then((m) => m.OpsModule),
  },
  {
    path: 'directory',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/directory.module').then((m) => m.DirectoryModule),
  },
  {
    path: 'quotation',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/quotation.module').then((m) => m.QuotationModule),
  },
  {
    path: 'signup',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/signup.module').then((m) => m.SignUpModule),
  },
  {
    path: 'order',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'caterings',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/caterings.module').then((m) => m.CateringsModule),
  },
  {
    path: 'feedbacks',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/feedback.module').then((m) => m.FeedbackModule),
  },
  {
    path: 'promo',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/promo-codes.module').then((m) => m.PromoCodesModule),
  },
  {
    path: 'orders',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'deliveries',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/deliveries.module').then((m) => m.DeliveriesModule),
  },
  {
    path: 'tasksheets',
    loadChildren: () =>
      import('./modules/tasksheets.module').then((m) => m.TasksheetsModule),
  },
  {
    path: 'daily-menu',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/daily-menu.module').then((m) => m.DailyMenuModule),
  },
  {
    path: 'supplier',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/supplier.module').then((m) => m.SupplierModule),
  },
  {
    path: 'finance',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/finance.module').then((m) => m.FinanceModule),
  },
  {
    path: 'customer',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'location',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/building.module').then((m) => m.BuildingModule),
  },
  {
    path: 'riders',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/riders.module').then((m) => m.RidersModule),
  },
  {
    path: 'food-item',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/food-item.module').then((m) => m.FoodItemModule),
  },
  {
    path: 'sms',
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/sms.module').then((m) => m.SmsModule),
  },
  {
    path: 'topup/top-up',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/topup.module').then((m) => m.TopupModule),
  },
  {
    path: 'pxl',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/pxl.module').then((m) => m.PxlModule),
  },
  {
    path: 'topup/nugget',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/topup-nugget.module').then((m) => m.TopupNuggetModule),
  },
  {
    path: 'subscriptions',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
  },
  {
    path: 'map',
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/map.module').then((m) => m.MapModule),
  },
  {
    path: 'corporate',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/corporate.module').then((m) => m.CorporateModule),
  },
  {
    path: 'corporate-payments',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/corporate-payments.module').then((m) => m.CorporatePaymentsModule),
  },
  {
    path: 'kpi',
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/kpi.module').then((m) => m.KpiModule),
  },
  {
    path: 'otp',
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/otp.module').then((m) => m.OtpModule),
  },
  {
    path: 'settings',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'releases',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/releases.module').then((m) => m.ReleasesModule),
  },
  {
    path: 'faq',
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'term',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule
      ),
  },
  {
    path: 'loyalty',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/loyalty.module').then((m) => m.LoyaltyModule),
  },
  {
    path: 'career',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/career.module').then((m) => m.CareerModule),
  },
  {
    path: 'bulk-update',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/bulk-update.module').then((m) => m.BulkUpdateModule),
  },
  {
    path: 'helpcenter',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/help-center.module').then((m) => m.HelpCenterModule),
  },
  // {
  //   path: 'reports',
  //   component: AdminLayoutComponent,
  //   loadChildren: () =>
  //   import('./feature_modules/reports/reports.module').then((m) => m.ReportsModule)
  // },
  {
    path: 'upcoming-order',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      $c: ['upcoming-order:view']
    },
    children: [
      {
        path: '',
        component: UpcomingOrdersComponent
      }
    ]
  },
  {
    path: 'cms',
    component: AdminLayoutComponent,
    loadChildren: () => import('./feature_modules/cms/cms.module').then(m => m.CmsModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: !environment.production,
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
