import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'addDays'
})
export class AddDaysPipe implements PipeTransform {
  transform(value: any,
    days?: number): any | null {
    console.log(value);
    return moment(value).add(days, 'days').utcOffset(+480).format('DD MMM YY');
  }
}
