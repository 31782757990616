import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit {

  @Output() onSelect = new EventEmitter<any>();
  @Input() showKey: string;
  @Input() matchKey: string;
  @Input() title: string;
  @Input() data: any[];
  @Input() selection: string;
  @Input() clear: EventEmitter<any>;

  ctrl = new UntypedFormControl();
  filteredArray: Observable<any[]> = this.ctrl.valueChanges
    .pipe(startWith(''), map(item => item ? this._filterArray(item) : this.data.slice()));

  constructor() {
  }

  ngOnInit() {
    if (this.selection) {
      this.ctrl.setValue(this.selection);
    }
    if (this.clear) {
      this.clear.subscribe(() => this.ctrl.setValue(''))
    }
  }

  select(item: any) {
    this.selection = item[this.showKey];
    this.ctrl.setValue(this.selection);
    this.onSelect.emit(item);
  }

  private _filterArray(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.data.filter(item => item[this.showKey].toLowerCase().indexOf(filterValue) === 0);
  }

}
