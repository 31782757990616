import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

@Injectable()
export class MenuItems {

  static items: Menu[] = [];

  static SetMenuItems(items: Menu[]) {
    MenuItems.items = items;
  }

  getAll(): Menu[] {
    return MenuItems.items;
  }
}
