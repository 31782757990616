import * as _ from 'lodash';

export class AddOn {

  _id: string;
  gmv = 0;
  nmv = 0;
  nmv_gold = 0;
  name: string;
  category: string;
  qty = 0;
  supplierId: string;
  total = 0;
  individualQuantity?: boolean;

  restaurantPrice?: number;
  marginFromRestaurant?: number;

  subTotalSelling?: number;
  subTotalRetailing?: number;
  subTotalRestaurantPrice?: number;
  taxSelling?: number;
  taxRetailing?: number;
  taxRestaurantPrice?: number;
  commission?: number;

  options: any[] = [];

  /**
   * @deprecated
   */
  gold = false;

  constructor(props: Object = {}) {
    Object.assign(this, props);
  }

  // public total(): number {
  //   return this.extraFees + _.sumBy(this.items, i => {
  //     return (i.quantity || 1) * (i.nmv || i.finalPrice)
  //   })
  // }
}
