export const environment = {
  environment: 'production-my',
  envName: 'production',
  hmr: false,
  production: true,
  sentry: {enabled: true, url: 'https://4b5a1bfb321e48c780b8e45bd60f202e@sentry.io/1256446'},
  baseUrl: 'https://admin.trysmartbite.com',
  newAdminUrl: 'https://ops.trysmartbite.com',
  eCommerceBaseUrl: 'https://trysmartbite.com',
  corporateGiftUrl: 'https://gifts.trysmartbite.com',
  vendorsBaseUrl: 'https://vendors.trysmartbite.com',
  apiUrl: 'https://production-api.trysmartbite.com/api',
  PUBLIC_FOOD_IMAGE_URL: 'https://images.trysmartbite.com/images/food',
  PUBLIC_SUPPLIERS_IMAGE_URL: 'https://images.trysmartbite.com/images/suppliers',
  PUBLIC_CHAINS_IMAGE_URL: 'https://images.trysmartbite.com/images/chains',
  CORPORATE_IMAGE_URL:'https://images.trysmartbite.com/images/corporate',
  wsAPI: 'https://ws.trysmartbite.com',
  corporateBaseUrl: 'https://corporate.trysmartbite.com',
  canteenBaseUrl: 'https://canteen.trysmartbite.com',
  currency: {
    iso2: 'RM',
    iso3: 'MYR',
    country: 'MY',
    tax: 0.06,
    vendorTax: 0.08,
    taxInPrice: false
  },
  smsRate: 0.03382000,
  picPickupDefaultContact: '+60 162991514'
};
