<mat-card>
  <mat-card-content>
    <div class="row">
      <!-- GMV -->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Retail price</mat-label>
          <input
            matInput
            [(ngModel)]="item.gmv"
            type="number"
            [readonly]="modificationLevel <= 2 || !newOrder || readonlyFields"
            (change)="changeItemTotal(item)"
          />
          <!--(input)="changeRestaurantPrice(item)"-->
        </mat-form-field>
      </div>

      <!-- NMV --->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Selling price</mat-label>
          <input
            matInput
            [(ngModel)]="item.nmv"
            type="number"
            (change)="changeItemTotal(item)"
            [readonly]="!editFinancial || modificationLevel <= 2 || !newOrder || readonlyFields">
        </mat-form-field>
      </div>

      <!-- RESTAURANT PRICE -->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Restaurant Price</mat-label>
          <input
            matInput
            [(ngModel)]="item.restaurantPrice"
            type="number"
            (change)="changeItemTotal(item)"
            [readonly]="modificationLevel <= 2 || !newOrder || readonlyFields">
        </mat-form-field>
      </div>

      <!-- MARGIN FROM RESTAURANT --->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Margin</mat-label>
          <input
            matInput
            [(ngModel)]="item.marginFromRestaurant"
            type="number"
            (change)="changeItemTotal(item)"
            [readonly]="!editFinancial || modificationLevel <= 2 || !newOrder || readonlyFields">
        </mat-form-field>
      </div>

      <!-- TOTAL --->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>Total</mat-label>
          <input
            matInput
            [(ngModel)]="item.total"
            type="number"
            readonly
            (change)="changeItemTotal(item)"
          />
        </mat-form-field>
      </div>

      <!-- QUANTITY --->
      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>QTY</mat-label>
          <input
            matInput
            [(ngModel)]="item.quantity"
            type="number"
            (change)="changeItemTotal(item)"
            [readonly]="modificationLevel <= 2 || !newOrder">
        </mat-form-field>
      </div>

      <!-- DESCRIPTION -->
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <!--<editor apiKey="o2bkelitv7z69wr0avfmr01hwernblc6u45lsvcozg5a3czi"-->
                  <!--[(ngModel)]="item.description"-->
                  <!--[disabled]="modificationLevel <= 2 || !newOrder || readonlyFields"-->
                  <!--[init]="{plugins: 'link lists codesample colorpicker image',-->
                    <!--toolbar: 'undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image'}"></editor>-->
          <quill-editor
            [(ngModel)]="item.description"
            (onEditorCreated)="created($event)"
            [disabled]="modificationLevel <= 2 || !newOrder || readonlyFields"
            [modules]="modules"
          ></quill-editor>
          <textarea matInput [ngModel]="item.description" name="description" style="display: none"
                    ></textarea>
        </mat-form-field>
      </div>

      <!-- INSTRUCTIONS -->
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Special instructions</mat-label>
          <textarea matInput id="special" name="special" maxlength="230" rows="3"
                    [(ngModel)]="item.specialInstructions" [readonly]="modificationLevel <= 1 || !newOrder"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<hr *ngIf="item.foodItemCustoms && item.foodItemCustoms.length">

<h5 class="font-weight-bold" *ngIf="item.foodItemCustoms && item.foodItemCustoms.length">Customizations</h5>
<div *ngFor="let c of item.foodItemCustoms" class="mt-3">
  <mat-card>
    <mat-card-content>
      <div class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <h5 class="font-weight-bold mb-0">{{c.index}} - {{c.name}}</h5>
          <mat-icon (click)="addAddon(c)" *ngIf="newOrder">plus_one</mat-icon>
        </div>

        <small class="customization-subtitle">
          <!-- MIN = MAX -->
          <span *ngIf="!!c.minAllowed && c.minAllowed > 0 && c.minAllowed === c.maxAllowed">
            Select {{c.minAllowed}}
          </span>

          <!-- MIN = MAX -->
          <span *ngIf="!!c.minAllowed && c.minAllowed > 0 && !c.maxAllowed">
            Minimum {{c.minAllowed}}
          </span>

          <!-- MIN != MAX -->
          <span *ngIf="!!c.maxAllowed && c.minAllowed !== c.maxAllowed && c.maxAllowed > 0">
            Up to {{c.maxAllowed}}
          </span>

          <span *ngIf="c.included">
            {{c.included}} included
          </span>

          <!-- OPTIONAL -->
          <span *ngIf="(c.minAllowed === 0 || !(!!c.minAllowed)) && !c.require">
            (optional)
          </span>
        </small>

        <div class="row" *ngFor="let a of (item.addons | filter:'category':c.itemType); let i = index">

          <!-- QTY -->
          <div class="col-12 col-md-2">
            <mat-form-field appearance="outline">
              <mat-label>Qty</mat-label>
              <input
                matInput
                [(ngModel)]="a.qty"
                type="number"
                [readonly]="modificationLevel <= 2 || !newOrder"
                (change)="changeItemTotal(item)"
              />
            </mat-form-field>
          </div>

          <!-- NAME -->
          <div class="col-12 col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput [matAutocomplete]="auto" placeholder="Select item..." [(ngModel)]="a.name"
                     [readonly]="modificationLevel <= 2 || !newOrder">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let m of a.options" [value]="m.name"
                            (onSelectionChange)="selectAddon(a, m, item, $event)">
                  {{m.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- PRICE -->
          <div class="col-12 col-md-2">
            <mat-form-field appearance="outline">
              <mat-label>Price</mat-label>
              <input
                matInput
                [(ngModel)]="a.nmv"
                type="number"
                (change)="changeItemTotal(item)"
                [readonly]="modificationLevel <= 2 || !newOrder || readonlyFields">
            </mat-form-field>
          </div>

          <!-- TOTAL -->
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline">
              <mat-label>Total</mat-label>
              <input
                matInput
                [(ngModel)]="a.total"
                type="number"
                readonly
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-md-1">
            <mat-icon class="delete" (click)="deleteAddon(c)" *ngIf="newOrder" style="margin-top: 15px;">delete
            </mat-icon>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
