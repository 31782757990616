import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent implements OnInit {
  message :'Are you sure?';
  title :'Delete all';
  noVisible: true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data.message){
      this.message =data.message;
    
    }

    if(data.title){
      this.title =data.title;
    
    }
    if(this.data.noVisible){
      this.noVisible = data.noVisible;
    }
  }

  ngOnInit() {
  }

}
