import { Injectable } from '@angular/core';
import { FoodCustomItem, FoodItem, FoodItemIngredient } from '@app/app.definitions';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '#env';
import { EMPTY } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FoodItemService {
  apiUrl: string = environment.apiUrl;
  componentApiUrl = this.apiUrl + '/food-item';

  constructor(private http: HttpClient) {
  }

  getAll(options: { offset?: number, limit?: number, sort?: string, order?: string; } = {}) {
    let params = new HttpParams();
    _.forEach(options, (v, k) => {
      if (v) params = params.set(k, v.toString());
    });
    return this.http.get(this.componentApiUrl, { params });
  }

  getFoodItemFullViewInfo(id: string): Observable<any> {
    const urlParams = '/' + id + '/full-info';
    return this.http.get(this.componentApiUrl + urlParams);
  }

  exportFoodItems(supplierIds, type = 'all', isChain = false): Observable<any> {
    let params = new HttpParams().set('type', type);
    if(isChain){
      params = params.set('isChain', '1')
    }
    if(supplierIds){
      params = params.set('supplierIds', supplierIds.join(','))
    }
    return this.http.get(this.apiUrl + '/export/all-food-items', { params: params });
  }
  getItemsForSupplier(supplierId, keyword = null, page = 1, limit = 999, isPromoPage = false, type = null,sort: string = 'name', order: string = 'asc' ? 'desc' : 'asc',): Observable<any> {
    let params = new HttpParams().set('sort', String(sort))
    .set('order', String(order)).set('supplierId', supplierId).set('page', String(page))
    .set('limit', String(limit));
    if(keyword){
      params = params.set('keyword', keyword)
    }
    if(isPromoPage){
      params = params.set('isPromoPage','true');
    }
    if(type){
      params = params.set('type', type);
    }
    return this.http.get(this.apiUrl + '/supplier/' + supplierId + '/food-item', { params: params });
  }

  getItemsForChain(chainId, keyword = null, page = 1, limit = 999, isPromoPage = false, type = null,sort: string = 'name', order: string = 'asc' ? 'desc' : 'asc'): Observable<any> {
    let params = new HttpParams().set('chainId', chainId).set('page', String(page)).set('limit', String(limit)).set('sort', String(sort))
    .set('order', String(order));
    if (keyword) {
      params = params.set('keyword', keyword)
    }
    if(isPromoPage){
      params = params.set('isPromoPage','true');
    }
    if(type){
      params = params.set('type', type);
    }
    return this.http.get(this.apiUrl + '/chain/' + chainId + '/food-item', {params: params});
  }

  getItem(id: string, supplierId?: string, type?: string): Observable<any> {
    const urlParams = '/' + id;
    let params = new HttpParams();
    if(type === 'supplier'){
      params = params.set('supplierId', supplierId);
    } else if(type === 'chain'){
      params = params.set('chainId', supplierId);
    }
    return this.http.get(this.componentApiUrl + urlParams, {params});
  }

  async getItemsBySlug(slug: string) {
    const params = '/slug/' + slug;
    return await this.http.get(this.componentApiUrl + params).toPromise();
  }

  saveItem(item: FoodItem, type: 'supplier' | 'chain' = 'supplier'): Observable<any> {
    const subcomponentApiUrl = this.apiUrl + '/' + type + '/' + (type === 'chain' ? item.chainId : item.supplierId) + '/food-item';

    let urlParams = '';
    if (item._id) {
      urlParams = '/' + item._id;
    }

    return this.http.post(subcomponentApiUrl + urlParams, item);
  }

  deleteItem(id: string,bulkIds=[]): Observable<any> {
    if (!id) {
      return EMPTY;
    }

    return this.http.delete(this.componentApiUrl + '/' + id,{body:{bulkIds:bulkIds}});
  }

  saveItemForChain(item: FoodItem): Observable<any> {
    const subcomponentApiUrl = this.apiUrl + '/chain/' + item.chainId + '/food-item';

    let urlParams = '';
    if (item._id) {
      urlParams = '/' + item._id;
    }

    return this.http.post(subcomponentApiUrl + urlParams, item);
  }

  deleteImage(id: string, filename: string, type: string): Observable<any> {
    const urlParams = '/delete-image/' + id + '/logo.png/'+type;
    return this.http.delete(this.componentApiUrl + urlParams,{body:{filename}});
  }

  uploadImage(foodItemId: string, data: any,type: string): Observable<any> {
    const urlParams = '/upload-image/' + foodItemId+'/'+type;
    return this.http.post(this.componentApiUrl + urlParams, data);
  }

  searchIngredients(search: string) {
    return this.http.get(this.apiUrl + '/food-item-ingredients?q=' + search);
  }

  allIngredients() {
    return this.http.get(this.apiUrl + '/food-item-ingredients/all');
  }

  saveIngredients(ingredient: FoodItemIngredient) {
    return this.http.post(this.apiUrl + '/food-item-ingredients', ingredient);
  }

  updateIngredients(id: string, ingredient: FoodItemIngredient) {
    return this.http.post(this.apiUrl + '/food-item-ingredients/' + id, ingredient);
  }

  updateSupplierStock(stock, supplierId) {
    return this.http.post(`${this.apiUrl}/food-item-stock/${supplierId}`, stock);
  }
}
