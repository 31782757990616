import {environment} from '#env';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Order} from '#defs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  toSlug(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  /**
   * Get difference between objects.
   * @param obj
   * @param base
   */
  difference(obj, base) {
    const __this = this;
    const diff = _.transform(obj, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        // append parent object _id if available, so to track back the change
        if (obj['_id']) {
          result['_id'] = obj['_id'];
        }

        if (_.isObject(value) && _.isObject(base[key])) {
          result[key] = __this.difference(value, base[key]);
        } else {
          result[key] = value;
        }
      }
    }) as any[];

    // remove empty values from array
    _.remove(diff, v => _.isEmpty(v));

    return diff;
  }

  getCurrency(iso = 2) {
    const {iso2, iso3} = environment.currency;
    return iso === 2 ? iso2 : iso3;
  }

  getTax() {
    const {tax} = environment.currency;
    return tax;
  }

  amountWithTax(amount, sst = 0, ignoreTax = false) {
    if (ignoreTax) {
      return 0;
    }
    const {taxInPrice} = environment.currency;
    if (taxInPrice || sst) {
      return amount * this.getTax();
    }

    return 0;
  }

  finalItemAmount(amount, sst = 0, ignoreTax = false) {
    return _.round(amount + this.amountWithTax(amount, sst, ignoreTax), 2);
  }

  orderSummary(i: Order){
    const deliveryFee = i?.deliveryFeeToVendor || i.deliveryFee || 0;
    const smartLogisticCost = i.deliveryCost || 0;
    const slcToDeliveryFee = i.orderType === 'delivery' && !i.isSelfDelivery && moment(i.dailyMenuForDate).isAfter(moment('2024-03-31T16:00:00').toDate()) ? deliveryFee : smartLogisticCost
    let creditCardCharges = 0;
    let takeRate = 0;
    let final = 0;
    let grossRevenue = 0;
    // filter only those not cancel
    i.orderItems = _.filter(i.orderItems, oi => oi.status !== 'cancelled');

    //SBRevenue
    let gm1 = 0;
    let gm2 = 0;
    const serviceCharge = Number(i?.serviceCharges || 0);

    //Promo
    const smartDiscount = (i?.totalSmartDiscountedAmount || 0) / i.orderItems.length;
    const offerDiscount = i?.totalOfferDiscountedAmount || 0;
    let promoCodeVendor = 0;
    let promoCodePartner = 0;
    let promoCodeSmartBite = 0;

    // GMV
    let gmv = 0; // GMV
    let gfv = 0; // GFV
    let sellingPrice = 0; // NMV
    let retailPrice = 0; // GMV
    let sstClient = i.fee || 0;
    let loyaltyByClient = 0;
    const loyaltyRedeemed = i.rewardPointsAmount || 0;
    let smartLogisticMargin = 0;
    let finalPay = 0;


    //RestaurantPrice
    let restaurantPrice = 0;
    let sstVendor = 0;
    let loyaltyByVendor = 0;
    let commission = 0;
    let markup = 0;
    let totalRevenue = 0;
    let commissionSST = 0;
    let caterTotalDue = 0;
    let paymentGatewayFee = 0;
    let vendorSST = i?.sstRate || environment.currency.tax;
    if (environment.currency.vendorTax && moment(i.dailyMenuForDate).isAfter(moment('2024-02-29T16:00:00').toDate())) {
      vendorSST = environment.currency.vendorTax;
    }
    const isDirectEntry = i.channel === 'direct-entry';

    // promo
    if (i.promo && i.totalDiscountedAmount) {
      promoCodeVendor = (i?.promo?.paidBy === 'vendor') ? (i.totalDiscountedAmount / i.orderItems.length) : 0;
      promoCodePartner = (i?.promo?.paidBy === 'partner') ? (i.totalDiscountedAmount / i.orderItems.length) : 0;
      promoCodeSmartBite = (i?.promo?.paidBy === 'smartbite') ? i.totalDiscountedAmount : 0;
    }

    // refund
    const customerCareFee = i.customerCareFee || 0;
    let refundVendorAmount = !i.refundPaidByVendor ? 0 : i.refundAmount || 0;
    const refundClientAmount = i.refundAmount || 0;
    const isVendorPromo = (i.promo && i?.promo?.paidBy === 'vendor' && i.totalDiscountedAmount) || i.channel === 'direct-entry' && i.totalDiscountedAmount;

    (i.orderItems || []).forEach(oi => {
      let addonSelling = 0; // NMV
      let addonRetailing = 0; // GMV
      let addonRestaurantPrice = 0;
      let addonMargin = 0;

      // calculate delivery fee per item
      if (oi.billed) {
        oi.deliveryFee = _.round((deliveryFee / (i.orderItems || []).length) || 0, 2);
        oi.mDeliveryFee = _.round((i.mDeliveryFee / (i.orderItems || []).length) || 0, 2);
      }

      // addons
      (oi.addons || []).forEach(a => {
        a.subTotalSelling = ((a.individualQuantity ? 1 : oi.quantity) * ((a.nmv || 0) * a.qty));
        a.subTotalRetailing = ((a.individualQuantity ? 1 : oi.quantity) * ((a.gmv || 0) * a.qty));
        a.subTotalRestaurantPrice = ((a.individualQuantity ? 1 : oi.quantity) * ((a.restaurantPrice || 0) * (a.qty || 1)));
        // making taxApplied here because we are calculating tax for client not vendor
        a.taxSelling = i.taxApplied ? (a.subTotalSelling * (i?.sstRate || environment.currency.tax)) : 0;
        a.taxRetailing = i.taxApplied ? (a.subTotalRetailing * (i?.sstRate || environment.currency.tax)) : 0;
        a.taxRestaurantPrice = i.sstApplied ? (a.subTotalRestaurantPrice * (i?.sstRate || environment.currency.tax)) : 0;
        a.commission = (a.subTotalRestaurantPrice * (!isDirectEntry ? (a.marginFromRestaurant || 0) : 0));
        addonSelling += a.subTotalSelling;
        addonRetailing += a.subTotalRetailing;
        addonRestaurantPrice += a.subTotalRestaurantPrice;
        addonMargin += a.commission;
      });

      oi.totalSellingPrice = ((oi.quantity * (oi.nmv || 0)));
      oi.totalRetailPrice = ((oi.quantity * (oi.gmv || 0)));
      oi.totalRestaurantPrice = ((oi.quantity * (oi.restaurantPrice || 0)));

      oi.commission = ((oi.totalRestaurantPrice - ((promoCodeVendor || 0) + (smartDiscount || 0))) * (!isDirectEntry ? (oi.marginFromRestaurant || 0) : 0)) + addonMargin || 0;
      oi.totalSellingPrice += addonSelling;
      oi.totalRetailPrice += addonRetailing;
      oi.totalRestaurantPrice += addonRestaurantPrice;
      oi.loyaltyByClient = (oi.supplierLoyaltyPercentage ? (oi.totalRetailPrice * (oi.supplierLoyaltyPercentage / 100)) : ((oi.rewardPointsAmount / 100) || 0)) || 0;
      oi.loyaltyByVendor = (oi.supplierLoyaltyPercentage ? (oi.totalRestaurantPrice * (oi.supplierLoyaltyPercentage / 100)) : ((oi.rewardPointsAmount / 100) || 0)) || 0;

      sellingPrice += oi.totalSellingPrice;
      retailPrice += oi.totalRetailPrice;
      restaurantPrice += oi.totalRestaurantPrice;
      loyaltyByClient += oi.loyaltyByClient;
      loyaltyByVendor += oi.loyaltyByVendor;

      oi.sstVendor = i.sstApplied ? ((i?.sstRate || environment.currency.tax) * (oi.totalRestaurantPrice || 0)) : 0;
      oi.sstClient = i.taxApplied ? ((i?.sstRate || environment.currency.tax) * (oi.totalRetailPrice || 0)) : 0;
      commission += oi.commission;
      oi.commissionSST = vendorSST * (oi.commission + oi.loyaltyByVendor);
      commissionSST += oi.commissionSST;
    });

    markup = (sellingPrice - restaurantPrice);
    const RP = restaurantPrice -
      (promoCodeVendor * i.orderItems.length) -
      (promoCodePartner * i.orderItems.length) -
      (smartDiscount * i.orderItems.length);

    if (i.sstApplied) {
      sstVendor = (RP) * (i?.sstRate || environment.currency.tax);
      if (environment.currency.country === 'PH') {
        if (i.isTaxExempted) {
          sstVendor += ((deliveryFee / (1 + (i?.sstRate || environment.currency.tax))) * (i?.sstRate || environment.currency.tax))
        } else {
          sstVendor += (deliveryFee * (i?.sstRate || environment.currency.tax))
        }
        commissionSST += (slcToDeliveryFee * vendorSST);
      }
    }

    if (environment.currency.country === 'SG') {
      commissionSST = 0;
    }
    totalRevenue = RP + sstVendor + deliveryFee;
    paymentGatewayFee = (RP + deliveryFee + sstVendor) * (i.orderItems[0]?.marginFromPaymentGateway || 0);
    if (environment.currency.country === 'MY' && slcToDeliveryFee && moment(i.dailyMenuForDate).isBefore(moment('2024-03-31T16:00:00').toDate())) {
      smartLogisticMargin = slcToDeliveryFee - (slcToDeliveryFee / 1.15);
    }

    caterTotalDue = totalRevenue - commission - commissionSST - slcToDeliveryFee - loyaltyByVendor - paymentGatewayFee - customerCareFee;
    gmv = sellingPrice + i.deliveryFee + (i.fee || 0) + (serviceCharge) + (Number(i.extraFees) || 0);
    gfv = sellingPrice;
    if (i.paidStatus === 'paid-online') {
      creditCardCharges = 0.0102 * i.totalAmount;
    }
    finalPay = i.totalAmount;

    gm2 = commission + loyaltyByVendor + paymentGatewayFee + markup;
    gm1 = serviceCharge;

    takeRate = gm1 / gmv;
    final = gm2 / gfv;
    grossRevenue = gm1 + gm2 - Math.max((slcToDeliveryFee - deliveryFee), 0) - loyaltyRedeemed - promoCodeSmartBite - creditCardCharges + smartLogisticMargin;

    if (!_.isEmpty(i.deliveryHistory) &&
      _.isEmpty(i.deliveryOrderId) &&
      (i.deliveryOrderStatus !== 'finished' && i.deliveryOrderStatus !== 'cancelled' && i.deliveryOrderStatus !== 're_booked_failed')
    ) {
      // first get the latest numOfTry if rider is not accepted
      const deliveryHistory = _.first(_.orderBy(_.filter(_.cloneDeep(i.deliveryHistory || []),
        dh => dh.status === 'booked' && dh.type === 'delivery_created' && dh.deliveryOrderId),
        ['timestamp', 'numOfTry'], ['desc', 'desc']));
      const numOfTry = deliveryHistory?.numOfTry || 1;

      if (deliveryHistory) {
        i.deliveryHistory = _.filter(i.deliveryHistory, delivery => {
          // remove only those who have same numOfTry
          if (numOfTry === delivery.numOfTry && delivery.status !== 'booked' && !!delivery.serviceProvider) {
            // maintain only those who have booking in progress
            if (delivery.deliveryOrderId) {
              return true;
            }

            // except num of try return all.
          } else {
            return true;
          }
          return false;
        });
      }
    }

    const lastDelivery = !_.isEmpty(i.deliveryHistory) && !_.isEmpty(i.deliveryOrderId)
      ? _.find(_.orderBy(i.deliveryHistory, ['timestamp'], ['desc']), ['deliveryOrderId', i.deliveryOrderId]) :
      _.last(i.deliveryHistory) || undefined;

    const lastDeliveryStatus = !_.isEmpty(lastDelivery)
      ? _.replace(lastDelivery['status'], /_/g, ' ') :
      i.deliveryOrderStatus ? _.replace(i.deliveryOrderStatus, /_/g, ' ') :
        '-';
    const serviceProvider = i.deliveryHistory && i.deliveryHistory[0]
      ? _.replace(_.last(i.deliveryHistory)['serviceProvider'], /_/g, ' ') : null;

    const isPastTime = moment(i.dailyMenuForDateEnd).isBefore(moment());
    const isReviewAvailable = moment(i.dailyMenuForDate).add(2, 'hours').isBefore(moment()) && (i.paymentStatus === 'paid' || i.paymentStatus === 'paylater');

    const isOnTime = _.isUndefined(i.isOnTime) ?
      !_.isEmpty(lastDelivery) && (lastDeliveryStatus === 'delivered' || i.deliveryOrderStatus === 'delivered' || lastDeliveryStatus === 'finished' || i.deliveryOrderStatus === 'finished') ?
        moment(lastDelivery.timestamp).isBefore(moment(i.dailyMenuForDateEnd)) : false
      : i.isOnTime

    const penaltyAmount = 0;
    const caterTotalDueWithoutRefund = caterTotalDue;
    if(refundVendorAmount && (caterTotalDue - refundVendorAmount) < 0){
      refundVendorAmount = caterTotalDue;
      caterTotalDue = 0;
    }else {
      caterTotalDue -= Math.max(refundVendorAmount, 0);
    }

    // Deduct the 20% from caterer total DUE but it should be revert because its HARAM
    if(i.is20Percent && moment(i.dailyMenuForDate).isBetween(moment('2024-08-31T16:00:00'), moment('2024-09-30T15:59:00'), undefined, '[]')){
      caterTotalDue -= (caterTotalDue*0.2)
    }
    finalPay -= refundClientAmount;
    const totalEmployeePaid = Math.max(sellingPrice - (i.totalCorporateAllowance || 0) - (i.isParent && i.type === 'smartcanteen'? 0 :(i.totalDiscountedAmount || 0)), 0);
    const totalEmployeePaidSST = i.taxApplied ? ((i?.sstRate || environment.currency.tax) * (totalEmployeePaid || 0)) : 0;

    return {
      ...i,
      lastDeliveryStatus,
      serviceProvider,
      isPastTime,
      isReviewAvailable,
      isOnTime,
      gmv,
      restaurantPrice,
      sellingPrice,
      retailPrice,
      RP,
      margin: (i.orderItems || []).length  && !isDirectEntry ? (i.orderItems[0].marginFromRestaurant * 100) + i.orderItems[0].supplierLoyaltyPercentage : 0,
      serviceCharge,
      creditCardCharges,
      sstClient,
      sstVendor,
      gm1,
      gm2,
      takeRate,
      final,
      loyaltyRedeemed,
      loyaltyByClient,
      loyaltyByVendor,
      promoCodeVendor,
      promoCodePartner,
      promoCodeSmartBite,
      commission,
      commissionSST,
      markup,
      caterTotalDue,
      totalRevenue,
      grossRevenue,
      paymentGatewayFee,
      trackingUrl: lastDelivery?.courier?.tracking_url && moment(i.dailyMenuForDate).endOf('day').isSameOrAfter(moment(new Date()).endOf('day')) ? lastDelivery.courier.tracking_url : null,
      smartLogisticMargin,
      gfv,
      marginFromRestaurant: (i.orderItems || []).length && !isDirectEntry ? i.orderItems[0].marginFromRestaurant : 0,
      marginFromPaymentGateway: (i.orderItems || []).length && !isDirectEntry ? i.orderItems[0].marginFromPaymentGateway : 0,
      marginFromLoyalty: (i.orderItems || []).length && !isDirectEntry ? i.orderItems[0].supplierLoyaltyPercentage : 0,
      smartDiscount,
      offerDiscount,
      penaltyAmount,
      customerCareFee,
      refundVendorAmount,
      refundClientAmount,
      finalPay,
      slcToDeliveryFee,
      smartLogisticCost,
      totalEmployeePaid,
      totalEmployeePaidSST,
      deliveryFee: i.deliveryFee,
      deliveryFeeToVendor: i?.deliveryFeeToVendor || 0,
      totalSmartDiscountedAmount: i.totalSmartDiscountedAmount || 0,
      deliveryCost: smartLogisticCost,
      totalDiscountedAmount: i.totalDiscountedAmount || 0
    }
  }
}
