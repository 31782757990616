import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpInterceptor, HttpParams, HttpErrorResponse
} from '@angular/common/http';
import {AuthenticationService} from '@app/services/authentication.service';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {environment} from '@app/../environments/environment';

const API = (affix) => environment.apiUrl + '/' + affix;

import {Supplier, User, IResponse, Role, Response, VendorAccount} from '#defs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {

  constructor(private http: HttpClient) {
  }

  public getKpi(): Observable<any> {
    return this.http.get(API('kpis'));
  }

  public getOtp(): Observable<any> {
    return this.http.get(API('suppliers/otp'));
  }

  public getOtpToday(): Observable<any> {
    return this.http.get(API('suppliers/otp-today'));
  }
  // Refund Hook
  public createRefundRequest(string, data): Observable<any> {
    return this.http.post(API(`refund/add`), data);
  }

  public getSuppliers(status: string, zoneId?: string, select?: string, keyword?: string, page: number = 1, limit = 999): Observable<any> {
    let params = new HttpParams().set('status', status);
    if (zoneId) {
      params = params.set('zoneId', zoneId);
    }
    if (select) {
      params = params.set('select', select);
    }
    if (keyword) {
      params = params.set('keyword', keyword);
    }
    if (page) {
      params = params.set('page', page);
    }
    if (limit) {
      params = params.set('limit', limit);
    }

    return this.http.get(API('supplier'), {params});
  }

  public getSupplierChain(status: string, zoneId?: string, select?: string): Observable<any> {
    let params = new HttpParams().set('status', status);
    return this.http.get(API('supplierchain'), {params});
  }


  public getCorporates(status: string, zoneId?: string, select?: string): Observable<any> {
    let params = new HttpParams().set('limit', 5000);
    params = params.set('offset', 0);

    return this.http.get(API('corporates'), {params});
  }

  public getSupplier(id: string): Observable<any> {
    return this.http.get(API('supplier/' + id));
  }

  public getSuppliersWhoHvPromotion(limit: number = -1): Observable<any> {
    return this.http.get(API('supplier/have-promotion/' + limit)).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public getPromotionItemBySupplier(supplierId: string): Observable<any> {
    return this.http.get(API('supplier/promotion-items/' + supplierId)).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public searchFoods(keyword: string): Observable<Array<any>> {
    return this.http.post(API('food-item-search'), {keyword: keyword}).pipe(
      map((res: IResponse<any>) => {
        return res.data.items;
      })
    );
  }

  public saveSupplier(supplier: Supplier): Observable<any> {
    return this.http.post(API('supplier' + (supplier._id ? `/${supplier._id}` : '')), supplier);
  }

  public updateReportStatus(_id: String,status: String): Observable<any> {
    return this.http.post(API('vendorInvoices'), {action:'statusUpdateAdmin',itemid:_id,status});
  }


  public patchSupplier(supplier: any): Observable<any> {
    return this.http.patch(API('supplier' + (supplier._id ? `/${supplier._id}` : '')), supplier);
  }

  public updatePromotions(req, supplierId): Observable<any> {
    return this.http.post(API('supplier/promotion-items/' + supplierId + '/update'), req).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public deletePromotions(supplierId, itemId): Observable<void> {
    return this.http.delete(API('supplier/promotion-items/' + supplierId + '/delete/' + itemId)).pipe(
      map((data: IResponse<any>) => {
        if (data.error) {
          throw data.error;
        }
      })
    );
  }

  public deleteSupplier(id: number,bulkIds=[]): Observable<any> {
    return this.http.delete(API(`supplier/${id}`), {body:{bulkIds:bulkIds}});
  }

  public getUsers(): Observable<Array<User>> {
    return this.http.get(API('users')).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.users;
        }
      })
    );
  }

  public getUser(id: string): Observable<User> {
    return this.http.get(API('user/' + id)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.user;
        }
      })
    );
  }

  public createUser(user: User): Observable<User> {
    return this.http.post(API(`user/create`), user).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.user;
        }
      })
    );
  }

  public deleteUser(id: string): Observable<User> {
    return this.http.delete(API(`user/${id}`)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.user;
        }
      })
    );
  }

  public deleteRole(id: string): Observable<Role> {
    return this.http.delete(API(`role/${id}`)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.role;
        }
      })
    );
  }

  public getRoles(): Observable<Array<Role>> {
    return this.http.get(API('roles')).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.roles;
        }
      })
    );
  }

  public getRole(_id: string): Observable<Role> {
    return this.http.get(API('role/' + _id)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.roles[0];
        }
      })
    );
  }

  public createRole(role: Role): Observable<Role> {
    return this.http.post(API(`role/create`), role).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.role;
        }
      })
    );
  }

  public loginUser(username: string, password: string, stayLogged: boolean,otpCode:string): Observable<any> {
    return this.http.post(API('authenticate'), {
      username: username,
      password: password,
      stayLogged: stayLogged,
      otpCode:otpCode
    });
  }

  public loginRestaurant(username: string, password: string, stayLogged: boolean): Observable<any> {
    const data = {
      email: username,
      password: password,
      stayLogged: stayLogged
    };
    return this.http.post(API('supplier-login'), data).pipe(
      map((res: IResponse<any>) => {
        if (res.error) {
          throw res.error;
        }
        return res;
      })
    );
  }

  /**
   * [R] Get orders report
   * @param {string} date
   * @param toDate
   * @returns {Observable<Array<any>>}
   */
  public ordersReport(date: string, toDate?: string): Observable<Array<any>> {
    return this.http.get(API('restaurant/reports/' + date + (toDate ? `/${toDate}` : ''))).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.items;
        }
      })
    );
  }

  /**
   * [R] Get upcoming orders report
   * @param {string} date
   * @param toDate
   * @returns {Observable<Array<any>>}
   */
  public upcomingOrdersReport(date: string, toDate?: string): Observable<Array<any>> {
    return this.http.get(API('restaurant/upcoming-orders/' + date)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.items;
        }
      })
    );
  }

  /**
   * [R] Get restaurant Items
   * @returns {Observable<Array<any>>}
   */
  public restaurantItems(): Observable<Array<any>> {
    return this.http.get(API('restaurant/items')).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.items;
        }
      })
    );
  }

  /**
   * [R] Get daily restaurant menu items
   * @returns {Observable<Array<any>>}
   */
  public restaurantDailyMenuItems(date: string, allSupp: boolean = false): Observable<Array<any>> {
    return this.http.get(API('restaurant/daily-items/' + date + '/allSupplier/' + allSupp)).pipe(
      map((res: IResponse<any>) => {
        if (res.data) {
          return res.data.items;
        }
      })
    );
  }

  /**
   * [R] Set food items status
   * @param {object} data
   * @returns {Observable<Array<any>>}
   */
  public setFoodItemStatus(data: object): Observable<void> {
    return this.http.post(API('restaurant/change-food-item-status'), data).pipe(
      map((res: IResponse<any>) => {
        if (res.error) {
          throw res.error;
        }
      })
    );
  }

  public setNotificationsAsRead(_id: string): Observable<any> {
    return this.http.post(API('notifications/' + _id), {});
  }

  createEditVendorAccount(account: VendorAccount) {
    return this.http.post(API('vendor-account'), account);
  }


  deleteVendorAccount(id: string): Observable<any> {
    return this.http.delete(API('vendor-account/' + id)).pipe(
      catchError(error => {
        return throwError(error)
      })
    );
  }

  vendorResetPassword(email: string) {
    return this.http.post(API('supplier-request-password'), {email});
  }

  corporateResetPassword(email: string) {
    return this.http.post(API('corporate-request-password'), {email});
  }

  addReviewFromOrder(supplierId: string, orderId: string, arrivedOnTime: Boolean, rating: Number, comment: string): Observable<any> {
    return this.http.post(API(`supplier/review/${supplierId}/${orderId}`), {arrivedOnTime, rating, comment});
  }
  addReviewResponse(supplierId: string, orderId: string, reviewId:string, comment: string,responseId :string,isUpdate :boolean): Observable<any> {
    return this.http.post(API(`supplier/review/${supplierId}/${orderId}`), {comment,reviewId,responseId,isUpdate});
  }
  removeReviewResponse(supplierId: string, orderId: string,reviewId:string,responseId :string,isDeleted :boolean): Observable<any> {
    return this.http.post(API(`supplier/review/${supplierId}/${orderId}`), {reviewId,responseId,isDeleted});
  }

  bulkUpdate(data): Promise<any> {
    return new Promise(resolve => {
      this.http.post(API(`bulk-update`), { data })
        .subscribe(
          (response: Response) => {
            if (!response.success) {
              resolve(null);
              return alert('Failed to update');
            }

            resolve(response.data);
          },
          error => {
            resolve(null);
            console.error(error);
            alert('An error occurred, please check the logs');
          });
    });
  }

}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private auth: AuthenticationService,
              private _snackBar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authenticationService.GetToken() || req.url.includes('scalefusion')) {
      return next.handle(req);
    }

    const headers = req.headers
      .append('x-access-token', this.authenticationService.GetToken())
      .append('Cache-Control', 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0')
      .append('Access-Control-Allow-Origin', '*');

    const dup = req.clone({headers});
    return next.handle(dup)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            if (!body.success && body.error && (body.error.error === 'EXPIRED_TOKEN' || body.error.error === 'MISSING_TOKEN')) {
              this.router.navigate(['login'], {queryParams: {error: 'Session expired, please login to continue'}});
              this.auth.logout();
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
            const reason = error && error.error && error.error.error && error.error.error.description
              ? error.error.error.description : 'There was an error processing your request. Please try again.';
            this._snackBar.open(reason, 'Okay', {duration: 2000});
            return throwError(error);
          }
        )
      );
  }
}

