<div class="row">
  <div class="col-8">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [formControl]="datex" [placeholder]="placeholder" (dateChange)="dateChange();">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker  #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-2">
    <button mat-mini-fab class="text-white" color="primary" (click)="Prev();">Prev</button>
  </div>
  <div class="col-2">
    <button mat-mini-fab class="text-white" color="primary" (click)="Next();">Next</button>
  </div>
</div>
