import { Injectable } from '@angular/core';
import { IGroupCapabilities } from '#defs';

/**
 * ACL is defining a key, which if a user has it or not, then is capable
 * to access a feature on the app. We do not store this list on server, and client list
 * is the source of the truth
 */
@Injectable({
  providedIn: 'root',
})
export class AclService {

  static Capabilities: Array<IGroupCapabilities> = [];

  static SetItems(capabilities: Array<IGroupCapabilities>) {
    AclService.Capabilities = capabilities;
  }

  constructor() {
  }

  public GetGroupedCapabilities(): IGroupCapabilities[] {
    return AclService.Capabilities;
  }
}
