import {FormControl} from '@angular/forms';
import * as lpn from 'google-libphonenumber';

export const phoneNumberValidator = (control: FormControl) => {
  const isCheckValidation = document.getElementById('phone').getAttribute('validation');
  if (isCheckValidation === 'true') {
    const isRequired = control.errors && control.errors.required === true;
    const error = {validatePhoneNumber: {valid: false}};
    let num: lpn.PhoneNumber;

    try {
      num = lpn.PhoneNumberUtil.getInstance().parse(control.value.number, control.value.countryCode);
    } catch (e) {
      if (isRequired === true) {
        return error;
      }
    }

    if (control.value) {
      if (!num) {
        return error;
      } else {
        if (!lpn.PhoneNumberUtil.getInstance().isValidNumberForRegion(num, control.value.countryCode)) {
          return error;
        }
      }
    }
  } else if (isCheckValidation === 'false') {
    control.clearValidators();
  }
  return;

};
